import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {
    Grid,
    Select,
    TextField,
    FormControl,
    InputLabel,
    Stack,
    MenuItem,
    Typography, Box, Button
} from '@mui/material';
import { BaseTable, TableFilterContainer } from 'src/components/table';
import SearchButton from 'src/components/buttons/SearchButton';
import BaseModal from 'src/components/BaseModal';
import BaseDialog from 'src/components/BaseDialog';
import { rowArrayToObject } from 'src/utils/Util';
import {
    DefaultPaginationData,
    getSelectOptions,
    getLabelByValue,
    Capability, NumberSearch
} from 'src/constants/index';
import {CommonService, NumberService, ProductsService, CartService} from 'src/api/services';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { useTranslation } from 'react-i18next';
import BaseButton from "../../../components/buttons/BaseButton";
import BuyNumberForm from "../number/Forms/BuyNumberForm";
import FileUploadForm from "../number/Forms/FileUploadForm";
import {useStore} from "../../../store/Store";
import BaseCard from 'src/components/BaseCard';
import BaseSelect from 'src/components/BaseSelect';
import BaseTextField from 'src/components/BaseTextField';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import RequestForm from "../number/Forms/RequestForm";
import NumberPortoutForm from "../number/Forms/NumberPortoutForm";
import NumberRequestForm from "../number/Forms/NumberRequestForm";
import {useNavigate} from "react-router-dom";

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const DIALOG_PROPERTIES = {
    fullWidth: true,
    maxWidth: "lg",
    scroll: "body",
    // fullScreen: true,
}

const TitleStyle = styled(Typography)(({ theme }) => ({
    color: theme.palette.background.contrastText,
    display: "inline"
}));

export default function BuyBlocks() {
    const { t } = useTranslation();
    const [store, dispatch] = useStore();
    const navigate = useNavigate();

    const providersEnabled = store.portalSettings.services.access_management.display_provider_codes ?? false;

    const usFeaturesEnabled = store.portalSettings.services.access_management.us_features ?? false;

    const displayPreOrders = store.portalSettings.services.portal_features.display_pre_orders ?? false;
    
    const TABLE_HEAD = providersEnabled ? [
        { key: "block_id", label: "Block ID"},
        { key: "block_start", label: "Block Start"},
        { key: "block_end", label: "Block End"},
        { key: "block_size", label: "Block Size"},
        { key: "provider_code", label: "Provider" },
        { key: "capability", label: "Capability" },
        { key: "did_type", label: "Type" },
        { key: "actions", label: t('actions') },

    ] : [
        { key: "block_id", label: "Block ID"},
        { key: "block_start", label: "Block Start"},
        { key: "block_end", label: "Block End"},
        { key: "block_size", label: "Block Size"},
        { key: "capability", label: "Capability" },
        { key: "did_type", label: "Type" },
        { key: "actions", label: t('actions') },
    ];

    const TABLE_FIELD_MAPPING = providersEnabled ? {
        block_id: { key: "block_id", index: 0},
        block_start: { key: "block_start", index: 1},
        block_end: { key: "block_end", index: 2},
        block_size: { key: "block_size", index: 3},
        provider_code: { key: "provider_code", index: 4},
        capability: { key: "capability", index: 5 },
        did_type: { key: "did_type", index: 6 },
    } : {
        block_id: { key: "block_id", index: 0},
        block_start: { key: "block_start", index: 1},
        block_end: { key: "block_end", index: 2},
        block_size: { key: "block_size", index: 3},
        capability: { key: "capability", index: 4 },
        did_type: { key: "did_type", index: 5 }
    };

    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [openDeallocateModal, setDeallocateModalStatus] = useState(false);
    const [openBuyNumberStatus, setOpenBuyNumberStatus] = useState(false);
    const [openFileUpload, setOpenFileUpload] = useState(false);
    const [data, setData] = useState([]);
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);
    const [preOrderCities, setPreOrderCities] = useState([]);
    const [preOrderStates, setPreOrderStates] = useState([]);

    const [filterCity, setFilterCity] = useState(0);
    const [filterState, setFilterState] = useState(0);

    const [filterPreOrderCity, setFilterPreOrderCity] = useState(0);
    const [filterPreOrderState, setFilterPreOrderState] = useState(0);


    const [numberTypes, setNumberTypes] = useState([]);
    const [providerCodes, setProviderCodes] = useState([]);
    const [providerCodesForPreOrder, setProviderCodesForPreOrder] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [filterProvider, setFilterProvider] = useState("");
    const [filterNumberSearch, setFilterNumberSearch] = useState("");
    const [filterCapability, setCapability] = useState("");
    const [filterCountry, setFilterCountry] = useState("");
    const [filterNumber, setFilterNumber] = useState("");
    const [filterNumberTypes, setFilterNumberTypes] = useState("");
    const [filterNpa, setFilterNpa] = useState("");
    const [filterNxx, setFilterNxx] = useState("");


    const [preOrderCount, setPreOrderCount] = useState(1);
    const [preOrderCountry, setPreOrderCountry] = useState(filterCountry);
    const [preOrderProduct, setPreOrderProduct] = useState(undefined);
    const [filterPreOrderProvider, setFilterPreOrderProvider] = useState('');
    const [filterPreOrderType, setFilterPreOrderType] = useState('');
    const [filterPreOrderCapability, setFilterPreOrderCapability] = useState(1);
    const [preordernotes, setpreordernotes] = useState('');
    const [openPreorder, setOpenPreorder] = useState(false);
    const [openPreorderFileUpload, setOpenPreorderFileUpload] = useState(false);

    const [addedCartItems, setAddedCartItems] = useState([]);

    const fetchNumbers = () => {
        getCart();
        setLoadingData(true);
        setData([]);
        setOpenFileUpload(false);
        setOpenBuyNumberStatus(false);
        const params = {
            country: filterCountry ? filterCountry == "0" ? undefined : filterCountry : undefined,
            capability: filterCapability ? filterCapability == "0" ? undefined : filterCapability : undefined,
            number_type: filterNumberTypes ? filterNumberTypes == "0" ? undefined : filterNumberTypes : undefined,
            provider_code: filterProvider ? filterProvider == "0" ? undefined : filterProvider : undefined,
            number_regex: filterNumberSearch ? filterNumberSearch : undefined,
            number_value: filterNumber ? filterNumber : undefined,
            npa: filterNpa ? filterNpa : undefined,
            nxx: filterNxx ? filterNxx : undefined,
            page: paginationData.page + 1,
            size: paginationData.rowsPerPage,
            country_state: filterState != 0 ? filterState : undefined,
            country_city: filterCity != 0 ? filterCity : undefined
        };

        NumberService.getBuyNumbers(params)
            .then((response) => {
                let items = [];

                //setTotalCount(response.data.data.count);

                setPreOrderCountry(filterCountry);
                setFilterPreOrderType(filterNumberTypes);
                setFilterPreOrderCapability(filterCapability);
                setFilterPreOrderProvider(filterProvider);
                setFilterPreOrderState(filterState);
                setFilterPreOrderCity(filterCity);

                if(filterCapability == 0){
                    setFilterPreOrderCapability(1);
                }

                for (const idx in response.data.data.items) {
                    items.push({...response.data.data.items[idx], 'mobile_price':''})
                }

                response.data.data.items = items;
                items = [];

                for (const idx in response.data.data.items) {
                    let item = new Array(TABLE_HEAD.length - 1).fill({});
                    const itemData = response.data.data.items[idx];

                    item[TABLE_FIELD_MAPPING["did"].index] = {
                        ...TABLE_FIELD_MAPPING["did"],
                        value: itemData.did,
                      };

                    Object.entries(itemData).forEach(([key, value]) => {
                        if (key in TABLE_FIELD_MAPPING && key !== "did") {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }
                    });
                    items.push(item);
                }
                if(items.length == 0){
                    setPreOrderCountry(filterCountry);
                }
                //setData(items);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    const fetchCountries = () => {
        CommonService.getCountries({})
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ code: item[1]["code"], name: item[1]["name"], label: item[1]["name"], iso_code_2digit: item[1]["iso_code_2digit"] , value: item[1]["iso_code_2digit"] });
                })
                setCountries(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const fetchCities = (country, preOrder) => {
        CommonService.getCities({"country": country})
            .then((response) => {
                if(preOrder){
                    setPreOrderCities([]);
                }else{
                    setCities([]);
                }
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ id: item[1]["id"], value: item[1]["id"], state: item[1]["state"], label: item[1]["name"], iso_code_2digit: item[1]["iso_code_2digit"] });
                })
                if(preOrder){
                    setPreOrderCities(items);
                }else{
                    setCities(items);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const fetchStates = (country, preOrder) => {
        CommonService.getStates({"country": country})
            .then((response) => {
                if(preOrder){
                    setPreOrderStates([]);
                }else{
                    setPreOrderCities([]);
                }
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ value: item[1]["id"], label: item[1]["name"], iso_code_2digit: item[1]["iso_code_2digit"], id: item[1]["id"]});
                })
                if(preOrder){
                    setPreOrderStates(items);
                }else{
                    setStates(items);
                }
                console.log(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const fetchDIDTypes = (country) => {
        let params = {};
        if (country != undefined) {
            params = {
                country: country ? country == "0" ? undefined : country : undefined,
            }
        }else{
            params = {
                country: filterCountry ? filterCountry == "0" ? undefined : filterCountry : undefined,
            }
        }
        NumberService.getDIDTypes(params)
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ value: item[1]["id"], label: item[1]["name"], is_fixed: item[1]["is_fixed"], is_mobile:item[1]["is_mobile"] });
                })
                setNumberTypes(items);
                setFilterPreOrderType(items[2]['value']);
            })
            .catch((err) => {
                console.log(err);
            })
    };


    const fetchProduct = (country, provider, capability, type) => {
        const params = {
            country: country ? country : preOrderCountry ? preOrderCountry : "",
            state:filterPreOrderCity,
            city:filterPreOrderState,
            number_type: type ? type : filterNumberTypes == 0 ? "" : filterNumberTypes,
            capability:  capability ? capability : filterCapability ? filterCapability : "",
        };

        if(params.capability === "0"){
            params.capability = undefined;
        }

        setLoadingData(true);
        ProductsService.getProduct(params)
            .then((response) => {
                if(response.status == 200){
                    if(response.data.data != undefined){
                        setPreOrderProduct(response.data.data);
                    }else{
                        setPreOrderProduct(undefined);
                    }
                }
            })
            .catch((err) => {
                setMessage('Error while getting products');
                setSnackbarStatus(true);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }


    const fetchProviderCodes = (country, capability, type) => {
        const params = {
            country: country ? country : undefined,
            capability: capability ? capability : undefined,
            number_type: type ? type : undefined,
            country_state: filterState,
            country_city: filterCity
        }
        NumberService.getProviderCodes(params)
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ value: item[1]["code"], label: item[1]["code"]});
                })
                setProviderCodes(items);
            })
            .catch((err) => {
                setProviderCodes([]);
                console.log(err);
            })
    };

    const fetchProviderCodesForPreOrder = (country, capability, type) => {
        const params = {
            country: country ? country : undefined,
            capability: capability ? capability : undefined,
            number_type: type ? type : undefined,
            country_state: filterPreOrderState,
            country_city: filterPreOrderCity
        }
        NumberService.getProviderCodes(params)
            .then((response) => {
                let items = [];
                Object.entries(response.data.data).forEach((item) => {
                    items.push({ value: item[1]["code"], label: item[1]["code"]});
                })
                setProviderCodesForPreOrder(items);
                setFilterPreOrderProvider(items[0]['value']);
            })
            .catch((err) => {
                setProviderCodesForPreOrder([]);
                console.log(err);
            })
    };

    useEffect(() => {
        getCart();
        fetchProviderCodes();
        fetchCountries();
        fetchNumbers();
        fetchDIDTypes();
    }, [paginationData]);

    useEffect(() => {
        fetchProviderCodes(filterCountry, filterCapability, filterNumberTypes);
    }, [filterCountry, filterCapability, filterNumberTypes, filterCity, filterState]);


    useEffect(() => {
        fetchDIDTypes();
    }, [filterCountry]);



    const formatRowData = (rowData) => {
        let formatted = [];
        const currency = rowData.find((d) => d.key === "currency_symbol").value.toString();

        rowData.map((d, idx) => {
           if (d.key === "mrc") {
                formatted.push({
                    ...d,
                    value: currency + d.value.toString()
                });
            }
            else if (d.key === "nrc") {
                formatted.push({
                    ...d,
                    value: currency + d.value.toString()
                });
            }
            else if (d.key === "sms_price") {
                formatted.push({
                    ...d,
                    value: currency + d.value.toString()
                });
            }else if (d.key === "voice_price") {
                const voiceOffpeakPrice = rowData.find((d) => d.key === "voice_offpeak_price");
                const voiceWeekendPrice = rowData.find((d) => d.key === "voice_weekend_price");
                formatted.push({
                    ...d,
                    value: currency + d.value.toString() + "/" +voiceOffpeakPrice.value.toString()+ "/" + voiceWeekendPrice.value.toString()
                });
            }else if (d.key === "mobile_price") {
                const mobilePrice = rowData.find((d) => d.key === "voice_mobile_price");
                const payphonePrice = rowData.find((d) => d.key === "voice_payphone_price");
                formatted.push({
                    ...d,
                    value: currency + mobilePrice.value.toString() + '/' + payphonePrice.value.toString()
                });
            }
            else if (d.key === "capability") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(Capability(), d.value.toString()),
                });
            } else {
                formatted.push(d);
            }
        })
        return formatted;
    }

    const modalHandler = (modalType, index = undefined) => {
        if (modalType === "buy") {
            setOpenBuyNumberStatus(!openBuyNumberStatus);
        }if (modalType === "addToCart") {
            addToCart(data[index][TABLE_FIELD_MAPPING.did.index].value);
        }if (modalType === "removeFromCart") {
            removeFromCart(data[index][TABLE_FIELD_MAPPING.did.index].value);
        }else if (modalType === "buyChannel") {
            navigate(`/channels?buy=true&sell_product_id=${data[index][TABLE_FIELD_MAPPING.sell_product_id.index].value}&sell_product_name=${data[index][TABLE_FIELD_MAPPING.sell_product_name.index].value}`);
        }
        else if (modalType === "deallocate") {
            setDeallocateModalStatus(!openDeallocateModal);
        }

        if (index) { setSelectedRow(rowArrayToObject(data[index])) };
    }

    const addToCart = (did) => {
        CartService.addToCart({did: did})
            .then((response) => {
                if (response.data.status == true) {
                    localStorage.setItem("cart_id", response.data.data.cart_id);
                    setMessage("DID added to cart");
                    setSnackbarStatus(true);
                    fetchNumbers();
                } else{
                    setMessage(response.data.meta.msg);
                    setSnackbarStatus(true);
                }
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
                //setLoading(false);
            })
    }


    const removeFromCart = (did) => {
        let id = localStorage.getItem("cart_id");
        id = parseInt(id);
        if (addedCartItems.length == 1){
            clearCart(id);
        }else{
            CartService.removeFromCart({did: did})
                .then((response) => {
                    if (response.data.status == true) {
                        setMessage("DID removed from cart");
                        setSnackbarStatus(true);
                        fetchNumbers();
                    } else{
                        setMessage(response.data.meta.msg);
                        setSnackbarStatus(true);
                    }
                })
                .catch((err) => {
                    setMessage(err);
                    setSnackbarStatus(true);
                })
                .finally(() => {
                    //setLoading(false);
                })
        }
    }


    const clearCart = (cart_id) => {
        CartService.clearCart({cart_id: cart_id})
            .then((response) => {
                if (response.data.status == true) {
                    localStorage.removeItem("cart_id");
                    fetchNumbers();
                } else{
                    setMessage(response.data.meta.msg);
                    setSnackbarStatus(true);
                }
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
                //setLoading(false);
            })
    }

    const getCart = () => {
        setAddedCartItems([]);
        let id = localStorage.getItem("cart_id");
        id = parseInt(id);

        if (id){
            CartService.getCart({cart_id: id})
            .then((response) => {
                if (response.data.status == true) {
                    setAddedCartItems(response.data.data.did_list);
                    if(response.data.data.did_list.length == 0){
                        clearCart();
                    }
                }
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
                //setLoading(false);
            })
        }
    }


    const getActionItems = (index) => {
        return (
                <Stack direction="row" spacing={2}>
                    {data[index][TABLE_FIELD_MAPPING.channel_order_exists.index].value == false && data[index][TABLE_FIELD_MAPPING.channel_required.index].value == true &&
                    (<>
                        <BaseButton
                        children={<AddShoppingCartIcon sx={{marginRight:0.5}}/>}
                        label={'Buy Channel'}
                        onClick={() => modalHandler("buyChannel", index)} color={"--color-success"}
                        />
                    </>)
                    }
                    {addedCartItems.find((item) => item == data[index][TABLE_FIELD_MAPPING.did.index].value
                    ) ? (
                        <BaseButton
                            children={<RemoveShoppingCartIcon sx={{marginRight:0.5}}/>}
                            label={'Remove from Cart'}
                            onClick={() => modalHandler("removeFromCart", index)} color={"--color-danger"}
                        />
                    ) : (<BaseButton
                        children={<AddShoppingCartIcon sx={{marginRight:0.5}}/>}
                        label={'Add to Cart'}
                        onClick={() => modalHandler("addToCart", index)} color={"--color-success"}
                    />)}
                </Stack>
            )
    }

    const openFileDialog = () => {
        setOpenBuyNumberStatus(false);
        setOpenFileUpload(true);
    }

    const setCountry = (country) => {
        setFilterCountry(country);
    }

    const handlePreOrderCountChange = (num) => {
        const regex = /^[0-9\b]+$/;
        if (num === "" || regex.test(num) ) {
            setPreOrderCount(num);
        }
    };

    const handlePreOrderCountryChange = (country) => {
        setPreOrderCountry(country);
        fetchDIDTypes(country);
        fetchProviderCodes({country:country, capability:filterPreOrderCapability, number_type:filterPreOrderType});
    };


    useEffect(() => {
        fetchProduct(preOrderCountry, undefined, filterPreOrderCapability, filterPreOrderType);
    }, [preOrderCountry, filterPreOrderCapability, filterPreOrderType]);

    useEffect(() => {
        fetchProviderCodesForPreOrder(preOrderCountry, filterPreOrderCapability, filterPreOrderType);
    }, [preOrderCountry, filterPreOrderCapability, filterPreOrderType ]);


    function findObjectByValue(array, targetValue) {
        for (let i = 0; i < array.length; i++) {
            if (array[i].value === targetValue) {
                return array[i].is_fixed;
            }
        }
        return null;
    }

    useEffect(() => {
        if(findObjectByValue(numberTypes, filterNumberTypes)){
            fetchCities(filterCountry, false);
            fetchStates(filterCountry, false);
        }else{
            setCities([]);
            setStates([]);
            setFilterCity(0);
            setFilterState(0);
        }
    }, [filterNumberTypes, filterCountry]);

    useEffect(() => {
        if(findObjectByValue(numberTypes, filterPreOrderType)){
            fetchCities(preOrderCountry, true);
            fetchStates(preOrderCountry, true);
        }else{
            setFilterPreOrderCity(0);
            setFilterPreOrderState(0);
            setPreOrderCities([]);
            setPreOrderStates([]);
        }
    }, [filterPreOrderType, preOrderCountry]);


    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />

            <BaseDialog title={"Buy Number"} dialogProperties={DIALOG_PROPERTIES} closeCallback={fetchNumbers} open={openFileUpload} setOpen={openFileDialog} children={<FileUploadForm successCallback={fetchNumbers} formType="add" formData={selectedRow} setModalStatus={setOpenFileUpload} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseModal title={"Are you sure?"} open={openBuyNumberStatus} setOpen={setOpenBuyNumberStatus} children={<BuyNumberForm successCallback={fetchNumbers} formData={selectedRow} setOpenBuyNumber={setOpenFileUpload} setModalStatus={setOpenBuyNumberStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseDialog title={'Pre-Order Documents and Verification'} dialogProperties={DIALOG_PROPERTIES} closeCallback={() => setOpenPreorderFileUpload(false)} open={openPreorderFileUpload} setOpen={setOpenPreorderFileUpload} children={<NumberRequestForm successCallback={() => setOpenPreorderFileUpload(false)} extraData={{
                "provider_code": filterPreOrderProvider,
                "country": preOrderCountry,
                "state": preOrderStates.length > 0 ? preOrderStates.find((item) => item.value == parseInt(filterPreOrderState)) : undefined,
                "city": preOrderCities.length > 0 ? preOrderCities.find((item) => item.value == parseInt(filterPreOrderCity)) : undefined,
                "number_type": filterPreOrderType.toString(),
                "capability": filterPreOrderCapability.toString(),
                "quantity": parseInt(preOrderCount),
                "note":preordernotes
            }} formData={preOrderProduct} setModalStatus={setOpenPreorderFileUpload} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />

            <BaseCard>
            <TableFilterContainer>
                <Grid sx={{ alignItems: "center" }} container spacing={4}>
                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{t('country')}</InputLabel>
                            <BaseSelect
                                label={'country'}
                                labelId="filter-country-label"
                                name="country"
                                color="secondary"
                                value={filterCountry }
                                onChange={event => { setCountry(event.target.value) }}
                            >
                                {getSelectOptions(countries)}
                            </BaseSelect>
                        </FormControl>
                    </Grid>

                    {states.length > 0 && (<Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-state-label">{t('State')}</InputLabel>
                            <BaseSelect
                                label={'State'}
                                labelId="filter-state-label"
                                name="State"
                                color="secondary"
                                value = {filterState}
                                onChange={event => { setFilterState(event.target.value) }}
                            >

                                {getSelectOptions(states)}
                            </BaseSelect>
                        </FormControl>
                    </Grid>)}
                    {cities.length > 0 && (<Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-city-label">{t('City')}</InputLabel>
                            <BaseSelect
                                label={'City'}
                                labelId="filter-city-label"
                                name="City"
                                color="secondary"
                                value={filterCity }
                                onChange={event => { setFilterCity(event.target.value) }}
                            >
                                {getSelectOptions(cities)}
                            </BaseSelect>
                        </FormControl>
                    </Grid>)}


                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-type-label">{'Type'}</InputLabel>
                            <BaseSelect
                                label={'type'}
                                labelId="filter-type-label"
                                name="type"
                                color="secondary"
                                value={filterNumberTypes}
                                onChange={event => { setFilterNumberTypes(event.target.value) }}
                            >
                                {getSelectOptions(numberTypes)}
                            </BaseSelect>
                        </FormControl>
                    </Grid>

                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{'Capability'}</InputLabel>
                            <BaseSelect
                                label={'capability'}
                                labelId="filter-capability-label"
                                name="capability"
                                color="secondary"
                                value={filterCapability}
                                onChange={event => {setCapability(event.target.value) }}
                            >
                                {getSelectOptions(Capability())}
                            </BaseSelect>
                        </FormControl>
                    </Grid>
                    {providersEnabled && (<Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-provider-label">{"Provider"}</InputLabel>
                            <BaseSelect
                                label={"Provider"}
                                labelId="filter-provider-label"
                                name="provider"
                                color="secondary"
                                value={filterProvider}
                                onChange={event => { setFilterProvider(event.target.value) }}
                            >
                               
                                {getSelectOptions(providerCodes)}

                            </BaseSelect>
                        </FormControl>
                    </Grid>)}

                    {usFeaturesEnabled && (
                        <>
                        <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <BaseTextField
                                value={filterNpa}
                                label={t('NPA')}
                                name="number"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={event => { setFilterNpa(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <BaseTextField
                                value={filterNxx}
                                label={t('NXX')}
                                name="number"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={event => { setFilterNxx(event.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                    </>
                    )}

                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{'Block Search'}</InputLabel>
                            <BaseSelect
                                label={'number-search'}
                                labelId="filter-number-search-label"
                                name="number-search"
                                color="secondary"
                                value={filterNumberSearch}
                                onChange={event => { setFilterNumberSearch(event.target.value) }}
                            >
                                {getSelectOptions(NumberSearch())}
                            </BaseSelect>
                        </FormControl>
                    </Grid>
                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <BaseTextField
                                value={filterNumber}
                                label={t('number-0')}
                                name="number"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={setFilterNumber }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{'Block Size'}</InputLabel>
                            <BaseSelect
                                label={'block-size'}
                                labelId="filter-block-size-label"
                                name="block-size"
                                color="secondary"
                                value={filterNumberSearch}
                                onChange={event => { setFilterNumberSearch(event.target.value) }}
                            >
                                {getSelectOptions([{value:1, label:1 }, {value:10, label:10 }, {value:100, label:100 }, {value:1000, label:1000 }])}
                            </BaseSelect>
                        </FormControl>
                    </Grid>
                    <SearchButtonContainer item md={2} xs={12}>
                        <SearchButton onClick={() => { fetchNumbers() }} />
                    </SearchButtonContainer>
                </Grid>
                <br />
                <SearchButtonContainer item md={12} xs={12}>
                    <strong>Results:</strong>   <TitleStyle>Total <strong>{totalCount}</strong> records found.
                </TitleStyle>
                    <br />
                </SearchButtonContainer>
            </TableFilterContainer>
            </BaseCard>
            <br />



            {totalCount == 0 && !loadingData ? <>
                <></>
            </> : <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                actionItemPrep={getActionItems}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
                hideDids={true}
            />}
        </>
    );
}
