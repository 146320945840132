import PortalLayout from 'src/layouts/PortalLayout';
import { Number } from './number';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------


export default function NumbersSidebar() {
    const { t } = useTranslation();

    const TABS = [
        { id: 0, label: t('numbers-0'), component: <Number /> }
    ];

    const TITLE = t('numbers-0');

    return (
        <PortalLayout tabs={TABS} title={TITLE} />
    );
}
