import { styled } from '@mui/system';
import { Grid } from '@mui/material';
import { TableFilterContainer } from 'src/components/table';
import { useTranslation } from 'react-i18next';
import BaseButton from "../../../components/buttons/BaseButton";
import {NumberService} from "../../../api/services"

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));


export default function Reports({ }) {
    const { t } = useTranslation();
    function exportNumbers() {
        NumberService.exportNumbers()
            .then(response => {
                const contentType = response.headers['content-type'];
                const binaryData = [];
                binaryData.push(response.data);
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: contentType }));
                downloadLink.setAttribute('download', 'YourNumbers.csv');
                document.body.appendChild(downloadLink);
                downloadLink.click();
            })
            .finally(() => {
            });
    }


    return (
        <>
            <Grid sx={{ alignItems: "center", justifyContent: "flex-start" }} container spacing={6}>
                <Grid item xs={6} sx={{ alignItems: "center", justifyContent: "flex-start" }} container >
                    <strong>Report</strong>
                </Grid>

                <Grid item xs={6} sx={{ alignItems: "center", justifyContent: "flex-start" }} container >
                    <strong>Actions</strong>
                </Grid>

            </Grid>
            <br/>
            <TableFilterContainer>
                <Grid sx={{ alignItems: "center", justifyContent: "flex-start" }} container spacing={6}>
                    <Grid item xs={6} sx={{ alignItems: "center", justifyContent: "flex-start" }} container >
                        Your Numbers
                    </Grid>

                    
                    <Grid item xs={6} sx={{ alignItems: "center", justifyContent: "flex-start" }} container >
                        <BaseButton
                            label={'Download'}
                            onClick={()=> exportNumbers()} 
                        />
                    </Grid>
                   

                </Grid>
            </TableFilterContainer>
            <br />

        </>
    );
}
