import React, { useEffect } from 'react';
import {
    List,
    ListItemText,
    Divider,
    ListItem,
    Box
}
    from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PortingService } from 'src/api/services';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import BaseSnackbar from 'src/components/BaseSnackbar';
import {useTheme} from '@mui/styles'
import * as OrderService from "../../../api/services/Orders";
import {useStore} from "../../../store/Store";

export default function ShowPreOrderDetail() {

    const { t } = useTranslation();
    const [data, setData] = useState({dids:[], translations:[]});
    const { id } = useParams();
    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const [store, dispatch] = useStore();
    const theme = useTheme();
    const providersEnabled = store.portalSettings.services.access_management.display_provider_codes ?? false;

    const fetchOrderDetail = async () => {
        OrderService.getPreOrderDetail(id)
            .then((response) => {
                if (response.data.meta.code !== 200) {
                    navigate(`./not-found`);
                }
                setData(response.data.data);
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            });
    };

    useEffect(() => {
        fetchOrderDetail();
        return () => {
            setData([]);
        };
    }, [id]);


    return(


        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus}/>
            <Box sx= {{
                backgroundColor : 'white',
                border:'3em solid white',
                borderColor: theme.palette['--card-background-color'],
                borderRadius: 2,
                boxShadow: 12,
                margin: "auto",
                maxWidth: 800,

            }}>
                <List component="nav" aria-label="mailbox folders" >
                    <h1>Pre-Order #{id}</h1>
                    <br/>
                    <ListItem >
                        <ListItemText primary={t("id")} />
                        {data.id}
                    </ListItem>
                    <Divider />
                    <ListItem selected className='ListItem' >
                        <ListItemText primary= {t("country")} />
                        {data.country}
                    </ListItem>
                    <Divider />
                    <ListItem >
                        <ListItemText primary={t("DID Type")} />
                        {data.did_type}
                    </ListItem>
                    <Divider />
                    <ListItem selected >
                        <ListItemText primary={t("Capability")} />
                        {data.capability}
                    </ListItem>
                    <Divider/>



                    <ListItem >
                        <ListItemText primary={t("Quantity")} />
                        {data.quantity}
                    </ListItem>
                    <Divider/>
                    <ListItem selected>
                        <ListItemText primary={t("Fulfilled Count")} />
                        {data.fulfilled_count}
                    </ListItem>
                    <Divider/>
                    <ListItem >
                        <ListItemText primary={t("Route Type")} />
                        {data.route_type}
                    </ListItem>
                        <Divider/>

    {data?.trunk && (
        <>
        <ListItem >
        <ListItemText primary={t("Voice Interconnection")} />
                {data?.trunk}
            </ListItem>
            <Divider/></>
                        )}

                    {data?.tdm_country && (
                        <>
                            <ListItem >
                                <ListItemText primary={t("TDM Country")} />
                                {data?.tdm_country}
                            </ListItem>
                            <Divider/></>
                    )}

                    <ListItem selected>
                        <ListItemText primary={t("Requested At")} />
                        {data.requested_at ? dayjs(data.requested_at).format('DD/MM/YYYY  HH:mm:ss') : ""}
                    </ListItem>
                    <Divider/>
                    <ListItem >
                    <ListItemText primary={t("Finalized At")} />
                    {data.finalized_at ? dayjs(data.finalized_at).format('DD/MM/YYYY  HH:mm:ss') : ""}
                    </ListItem>
                    <Divider/>
                    <ListItem selected>
                        <ListItemText  primary={t("Status")} />
                        {data.status}
                    </ListItem>
                    <Divider/>
                    {providersEnabled && (<><ListItem  >
                        <ListItemText primary={t("Provider")} />
                        {data.provider_code}
                    </ListItem>
                        </>)}
                        <Divider/>
                        <><ListItem selected>
                        <ListItemText primary={t("Customer Note")} />
                        {data.note}
                        </ListItem>
                        </>
                    <div>
                        <br/>
                        <label style={{ fontSize: '20px', fontWeight: 'bold' }}>Translations</label>
                        <br/>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col">Number</th>
                                    <th scope="col">Landline</th>
                                    <th scope="col">Mobile</th>
                                    <th scope="col">Payphone</th>
                                    <th scope="col">Provided At</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.translations && data.translations.map((did, index) => (
                                    <tr key={index}>
                                        <th scope="col">{did.did ? did.did : 'Placeholder'}</th>
                                        <th scope="col">{did.landline}</th>
                                        <th scope="col">{did.mobile}</th>
                                        <th scope="col">{did.payphone}</th>
                                        <th scope="col">{(did.provided_at || '').slice(0, 19)}</th>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </List>
            </Box>


        </>

    )
}
