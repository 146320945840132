import { useState } from 'react';
// material
import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Stack,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import  {useStore} from "../../../store/Store";
import BaseButton from 'src/components/buttons/BaseButton';
import BaseTextField from 'src/components/BaseTextField';
import BaseSelect from 'src/components/BaseSelect';
import { getSelectOptions } from 'src/constants';
import {CommonService, NumberService, ProductsService, CartService} from 'src/api/services';
import {useEffect} from "react";
// ----------------------------------------------------------------------

export default function PreOrderNoteDialog({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();

    const [store, dispatch] = useStore();

    const providersEnabled = store.portalSettings.services.access_management.display_provider_codes ?? false;

    const [preOrderCount, setPreOrderCount] = useState(0);
    const [preordernotes, setpreordernotes] = useState('');
    const [filterPreOrderProvider, setFilterPreOrderProvider] = useState('0');
    const [filterPreOrderType, setFilterPreOrderType] = useState('0');
    const [filterPreOrderCountry, setFilterPreOrderCountry] = useState('0');
    const [filterPreOrderState, setFilterPreOrderState] = useState('0');
    const [filterPreOrderCity, setFilterPreOrderCity] = useState('0');
    const [preOrderCities, setPreOrderCities] = useState([]);
    const [preOrderStates, setPreOrderStates] = useState([]);
    const [numberTypes, setNumberTypes] = useState([]);
    const [preOrderCountry, setPreOrderCountry] = useState('0');
    const [filterCountry, setFilterCountry] = useState('0');

    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);

    const [filterState, setFilterState] = useState('0');
    const [filterCity, setFilterCity] = useState('0');
    

    const fetchCities = () => {
        CommonService.getCities({"country": formData.country_code})
            .then((response) => {
                    setPreOrderCities([]);
             
                let items = [];
                if(response.data.data){
                    Object.entries(response.data.data).forEach((item) => {
                        items.push({ id: item[1]["id"], value: item[1]["id"], state: item[1]["state"], label: item[1]["name"], iso_code_2digit: item[1]["iso_code_2digit"] });
                    })
                }
                    setPreOrderCities(items);
            
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const fetchStates = () => {
        CommonService.getStates({"country": formData.country_code})
            .then((response) => {
             
                    setPreOrderStates([]);
              
                let items = [];
                if(response.data.data){
                    Object.entries(response.data.data).forEach((item) => {
                        items.push({ value: item[1]["id"], label: item[1]["name"], iso_code_2digit: item[1]["iso_code_2digit"], id: item[1]["id"]});
                    })
                }
                setPreOrderStates(items);
             
                console.log(items);
            })
            .catch((err) => {
                console.log(err);
            })
    };

    const handlePreOrderCountChange = (num) => {
        const regex = /^[0-9\b]+$/;
        if (num === "" || regex.test(num) ) {
            setPreOrderCount(num);
        }
    };

    function findObjectByValue(array, targetValue) {
        for (let i = 0; i < array.length; i++) {
            if (array[i].value === targetValue) {
                return array[i].is_fixed;
            }
        }
        return null;
    }


    useEffect(() => {
        if(formData.did_type_is_fixed){
            fetchCities(formData.country_code);
            fetchStates(formData.country_code);
        }else{
            setFilterPreOrderCity(0);
            setFilterPreOrderState(0);
            setPreOrderCities([]);
            setPreOrderStates([]);
        }
    }, [formData]);


    const prepareData = () => {
        let data = {
            "count": preOrderCount,
            "notes": preordernotes
        }

        if (providersEnabled && filterPreOrderProvider !== '0') {
            data.provider = filterPreOrderProvider;
        }

        if (filterCity !== '0') {
            data.city =  preOrderCities.find((item) => item.value == parseInt(filterCity));
        }

        if (filterState !== '0') {
            data.state =  preOrderStates.find((item) => item.value == parseInt(filterState));
        }

        if (preOrderCount === 0) {
            setMessage("Please enter a valid count");
            setSnackbarStatus(true);
            return;
        }

        console.log(data);

        successCallback(data);
    }

    return (
        <>
            <Stack spacing={1}>
                <Typography component="subtitle1" variant="h6">
                    {formData.number_value}
                </Typography>

                {preOrderStates.length > 0 && (<Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-state-label">{t('State')}</InputLabel>
                            <BaseSelect
                                label={'State'}
                                labelId="filter-state-label"
                                name="State"
                                color="secondary"
                                value = {filterState}
                                onChange={event => { setFilterState(event.target.value) }}
                            >

                                {getSelectOptions(preOrderStates)}
                            </BaseSelect>
                        </FormControl>
                    </Grid>)}
                {preOrderCities.length > 0 && (<Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-city-label">{t('City')}</InputLabel>
                            <BaseSelect
                                label={'City'}
                                labelId="filter-city-label"
                                name="City"
                                color="secondary"
                                value={filterCity }
                                onChange={event => { setFilterCity(event.target.value) }}
                            >
                                {getSelectOptions(preOrderCities)}
                            </BaseSelect>
                        </FormControl>
                    </Grid>)}


                {providersEnabled && (<Grid item md={1.6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="filter-provider-label">{"Provider"}</InputLabel>
                                <BaseSelect
                                    label={"Provider"}
                                    labelId="filter-provider-label"
                                    name="provider"
                                    color="secondary"
                                    value={filterPreOrderProvider}
                                    onChange={event => { setFilterPreOrderProvider(event.target.value) }}
                                >
                                    {formData.provider_codes.map((provider, idx) => (
                                        <MenuItem key={idx} value={provider}>{provider}</MenuItem>
                                    ))
                                    }
                                </BaseSelect>
                            </FormControl>
                        </Grid>)}
                        <Grid item md={1.6} xs={12}>
                            <FormControl fullWidth>
                                <BaseTextField
                                    value={preOrderCount}
                                    label={t('Count')}
                                    name="number"
                                    margin="normal"
                                    variant="outlined"
                                    color="secondary"
                                    onChange={handlePreOrderCountChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={1.6} xs={12}>
                            <FormControl fullWidth>
                                <BaseTextField
                                    value={preordernotes}
                                    label={t('Notes (Optional)')}
                                    name="number"
                                    margin="normal"
                                    variant="outlined"
                                    color="secondary"
                                    onChange={setpreordernotes}/>
                            </FormControl>
                        </Grid>
                <br/>

                <Stack sx={{ display: "block" }} direction="row" spacing={2}>
                    <BaseButton
                        label={'Cancel'}
                        onClick={() => setModalStatus(false)} color={"--color-search"}
                    />
                    <BaseButton
                        label={t('Continue Pre-Order')}
                        onClick={prepareData}color={"--color-primary"}
                    />
                </Stack>
            </Stack>
        </>
    );
}
