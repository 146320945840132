import { Navigate, useRoutes } from 'react-router-dom';
import React from "react";
import { useStore } from 'src/store/Store';

// layouts
import MainLayout from './layouts/main';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Auth from './pages/Auth';
import NotFound from './pages/Page404';
import DashboardSidebar from './pages/app/DashboardSidebar';
import AddCreditSidebar from './pages/app/AddCreditSidebar';

import BuyNumbersSidebar from './pages/app/BuyNumbersSidebar';
import EndpointsSidebar from './pages/app/EndpointsSidebar';
import TransactionsSidebar from './pages/app/TransactionsSidebar';
import Documentation from './components/Documentation';
// ----------------------------------------------------------------------
import NumbersSidebar from './pages/app/NumbersSidebar';
import OrdersSidebar from "./pages/app/OrdersSidebar";
import PreOrdersSidebar from "./pages/app/PreOrdersSidebar";
import TranslationsSidebar from "./pages/app/Translations";
import DeallocationsSidebar from "./pages/app/Deallocations";
import ReportsTab from "./pages/app/ReportsSidebar";
import CDRsSidebar from "./pages/app/cdr/CDRs";
import OrganisationSidebar from "./pages/app/organisation/OrganisationSidebar";
import RoutingTranslationsSidebar from "./pages/app/routing/RoutingTranslationsSidebar";
import RoutingRoutingSidebar from "./pages/app/routing/routing/RoutingRouting";
import NotificationsFrame from "./pages/app/notifications/NotificationsFrame";
import PortInSidebar from "./pages/app/porting/portinsidebar";
import PortOutSidebar from "./pages/app/porting/portoutsidebar";
import ShowPortOuts from "./pages/app/porting/Forms/ShowPortOuts";
import PricingSidebar from "./pages/app/pricing/PricingSidebar";
import ViewProfile from "./pages/app/profile/ViewProfile";
import ChangePassword from "./pages/app/profile/ChangePassword";
import TranslateNumber from "./pages/app/porting/Translate";
import ProductsSidebar from './pages/app/products/ProductsSidebar';
import ShowOrderDetail from "./pages/app/orders/ShowOrderDetail";
import ShowPreOrderDetail from "./pages/app/orders/ShowPreOrderDetail";
import Channels from "./pages/app/channels/Channels";
import Identity from "./pages/app/compliance/identity";
import Addresstab from "./pages/app/compliance/addresstab";
import SubCustomerSidebar from "./pages/app/compliance/subcustomersidebar";
import VerificationSidebar from './pages/app/compliance/verification/verificationsidebar';
import CartFrame from './pages/app/shoppingcart/CartFrame';
import SendSMS from './pages/app/sendsms/SendSMS';
import SenderIDSidebar from './pages/app/senderid/senderidsidebar';
import SenderIDInventorySidebar from './pages/app/senderid/inventorysidebar';
import BuyBlocksSidebar from './pages/app/BuyBlocksSidebar';
import PreOrderRequestSidebar from './pages/app/preorders/PreOrderRequestSidebar';
// ----------------------------------------------------------------------


export default function Router() {
  const [store, dispatch] = useStore();

  const handleAppRedirect = () => {
    if (localStorage.getItem("token") !== null) {
      return <MainLayout />;
    }
    else {
      return <Navigate to="/login" />;
    }
  }

  return useRoutes([
    {
      path: '/',
      element: handleAppRedirect(),
      children: [
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "/dashboard", element: <DashboardSidebar /> },
        { path: "/add-credit", element: <AddCreditSidebar /> },
        { path: "/buy-numbers", element: <BuyNumbersSidebar /> },
        { path: "/buy-blocks", element: <BuyBlocksSidebar /> },
        { path: "/endpoints", element: <EndpointsSidebar /> },
        { path: "/numbers", element: <NumbersSidebar /> },
        { path: "/reports", element: <ReportsTab /> },
        { path: "/transactions", element: <TransactionsSidebar /> },
        { path: "/routing/translations", element: <RoutingTranslationsSidebar/>},
        { path: "/routing/routing", element: <RoutingRoutingSidebar/>},
        { path: "/send-sms", element: <SendSMS /> },
        { path: "/porting/port-in", element: <PortInSidebar/>},
        { path: "/porting/port-out", element: <PortOutSidebar/>},
        { path: "/sender-id/requests", element: <SenderIDSidebar/>},
        { path: "/sender-id/inventory", element: <SenderIDInventorySidebar/>},
        { path: "/pricing", element: <PricingSidebar/>},
        { path: "/products", element: <ProductsSidebar/>},
        { path: "/pre-order-request", element: <PreOrderRequestSidebar/>},
        { path: "/orders", element: <OrdersSidebar/>},
        { path: "/pre-orders", element: <PreOrdersSidebar/>},
        { path: "/orders/detail/:id", element: <ShowOrderDetail />},
        { path: "/pre-orders/detail/:id", element: <ShowPreOrderDetail />},
        { path: "/organisation", element: <OrganisationSidebar/>},
        { path: "/identity", element: <Identity/>},
        { path: "/addresses", element: <Addresstab/>},
        { path: "/sub-customers", element: <SubCustomerSidebar/>},
        { path: "/cdr", element: <CDRsSidebar/>},
        { path: "/channels", element: <Channels/>},
        { path: "/deallocations", element: <DeallocationsSidebar/>},
        //{ path: "/notifications", element: <NotificationsFrame/>},
        { path: "/shopping-cart", element: <CartFrame/>},
        { path: "/translations", element: <TranslationsSidebar/>},
        { path: "/port-out/details/:id", element: <ShowPortOuts />},
        { path: "/port-in/details/:id", element: <ShowPortOuts />},
        { path: "/profile", element: <ViewProfile /> },
        { path: "/change-password", element: <ChangePassword /> },
        { path: "/porting/translate/:id", element: <TranslateNumber />},
        { path: "/verifications", element: <VerificationSidebar />},
      ]
    },
    {
      path: '/panel/docs',
      element: <Documentation  />,
    },
    {
      path: '/',
      element: localStorage.getItem("token") === null ? <LogoOnlyLayout /> : <Navigate to="/" />,
      children: [
        { path: 'login', element: <Auth type="login" /> },
        { path: 'register', element: <Auth type="register" /> },
        { path: 'reset-password-request', element: <Auth type="reset-password" /> },
        { path: 'auth/reset_password', element: <Auth type="reset-password-email" /> },
        { path: 'auth/accept_invitation', element: <Auth type="accept-invitation" /> },
      ]
    },
    { path: '*', element: <NotFound /> }
  ]);
}
