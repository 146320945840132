import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Drawer, useTheme } from '@mui/material';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
import { useStore } from 'src/store/Store';
import { Icon } from '@iconify/react';
import lockFill from '@iconify/icons-eva/lock-fill';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import DialerSipIcon from '@mui/icons-material/DialerSip';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import MoneyIcon from '@mui/icons-material/Money';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import LoopIcon from '@mui/icons-material/Loop';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SettingsInputCompositeIcon from '@mui/icons-material/SettingsInputComposite';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import {Message, Person, Person2, Queue} from "@mui/icons-material";


// ----------------------------------------------------------------------

const DRAWER_WIDTH = 260;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

// ----------------------------------------------------------------------

AppSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};
const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

export default function AppSidebar({ isOpenSidebar, onCloseSidebar, isAdminLayout = false }) {
  const { pathname } = useLocation();
  const [store] = useStore();
  const [logo, setLogo] = useState("");
  const theme = useTheme();

  
  const displayTransactions = store.portalSettings.services.portal_features.display_transactions ?? false;
  const displayAddCredit = store.portalSettings.services.portal_features.display_add_credit ?? false;
  const displayRouting = store.portalSettings.services.portal_features.display_routing ?? false;
  const displayCdr = store.portalSettings.services.portal_features.display_cdr ?? false;
  const displaySenderId = store.portalSettings.services.portal_features.display_sender_id ?? false;
  const displaySMSSend = store.portalSettings.services.portal_features.display_sms_send ?? false;
  const displayPreOrders = store.portalSettings.services.portal_features.display_pre_orders ?? false;
  const displayBlocks = store.portalSettings.services.portal_features.display_blocks ?? false;

  const displayPorting = store.portalSettings.services.portal_features.display_porting ?? false;
  const portingFeature = store.portalSettings.services.access_management.porting ?? false;

  

  const appSidebarConfig =  [
    {
      title: 'Dashboard',
      path: '/dashboard',
      icon: <DashboardIcon/>,
      adminOnly: false,
      addDividerAfter: false,
      //requiredServices: ["programmable_sms"],
    },
    {
      title: 'Your Numbers',
      path: '/numbers',
      icon: <FormatListNumberedIcon/>,
      adminOnly: false,
      addDividerAfter: false,
    },
    {
      title: 'Change Requests',
      path: '/two-factor-authentication',
      icon: <DialerSipIcon/>,
      adminOnly: false,
      addDividerAfter: false,
      children: [
        {
          title: 'Translations',
          path: '/translations',
          icon: <DashboardIcon/>,
          adminOnly: false,
          addDividerAfter: false,
        },
        {
          title: 'Deallocations',
          path: '/deallocations',
          icon: <DashboardIcon/>,
          adminOnly: false,
          addDividerAfter: false,
        },
      ]
    },
    {
      title: 'Orders',
      path: '/campaign-management',
      icon: <ShoppingCartIcon/>,
      adminOnly: false,
      addDividerAfter: false,
      children: [
        {
          title: 'Orders',
          path: '/orders',
          icon: <DashboardIcon/>,
          adminOnly: false,
          addDividerAfter: false,
        },
        displayPreOrders ? {
          title: 'Pre-Orders',
          path: '/pre-orders',
          icon: <DashboardIcon/>,
          adminOnly: false,
          addDividerAfter: false,
        } : null,
      ].filter(item => item !== null)
    },
    {
      title: 'Buy Numbers',
      path: '/buy-numbers',
      icon: <ControlPointIcon/>,
      adminOnly: false,
      addDividerAfter: false,
    },
    displayPreOrders ?  {
      title: 'Pre-Order',
      path: '/pre-order-request',
      icon: <ShoppingCartIcon/>,
      adminOnly: false,
      addDividerAfter: false,
    } :null,
    displayBlocks ? {
      title: 'Buy Blocks',
      path: '/buy-blocks',
      icon: <Queue/>,
      adminOnly: false,
      addDividerAfter: false,
    } : null,
    {
      title: 'Endpoints',
      path: '/endpoints',
      icon: <RecordVoiceOverIcon/>,
      adminOnly: false,
      addDividerAfter: false,
    },
    {
      title: 'Billing',
      path: '/billing',
      icon: <MoneyIcon/>,
      adminOnly: false,
      addDividerAfter: false,
      children: [
        displayTransactions
        ? {
            title: 'Transactions',
            path: '/transactions',
            icon: <DashboardIcon />,
            adminOnly: false,
            addDividerAfter: false,
          }
        : null, 
        displayAddCredit ? {
          title: 'Add Credit',
          path: '/add-credit',
          icon: <DashboardIcon/>,
          adminOnly: false,
          addDividerAfter: false,
        } : null,
        {
          title: 'Pricing',
          path: '/pricing',
          icon: <DashboardIcon/>,
          adminOnly: false,
          addDividerAfter: false,
        },
        {
          title: 'Products',
          path: '/products',
          icon: <DashboardIcon/>,
          adminOnly: false,
          addDividerAfter: false,
        },
      ].filter(item => item !== null)
    },

   displayRouting ? {
      title: 'Routing',
      icon: <AltRouteIcon/>,
      adminOnly: false,
      addDividerAfter: false,
      children: [
        {
          title: 'Routing',
          path: '/routing/routing',
          icon: getIcon(lockFill),
          adminOnly: false,
          addDividerAfter: false,
        },
        {
          title: 'Translation',
          path: '/routing/translations',
          icon: getIcon(lockFill),
          adminOnly: false,
          addDividerAfter: false,
        }
      ]
    } : null,
    displaySMSSend ?     {
      title: 'Send SMS',
      path: '/send-sms',
      icon: <Message/>,
      adminOnly: false,
      addDividerAfter: false,
    } : null,
    displayPorting && portingFeature ? {
      title: 'Porting',
      icon: <LoopIcon/>,
      adminOnly: false,
      addDividerAfter: false,
      children: [
        {
          title: 'Port-In',
          path: '/porting/port-in',
          icon: getIcon(lockFill),
          adminOnly: false,
          addDividerAfter: false,
        },
        {
          title: 'Port-Out',
          path: '/porting/port-out',
          icon: getIcon(lockFill),
          adminOnly: false,
          addDividerAfter: false,
        }
      ]
    } : null,
    displaySenderId ?     {
      title: 'Sender-ID',
      icon: <Person2/>,
      adminOnly: false,
      addDividerAfter: false,
      children: [
        {
          title: 'Sender-ID Requests',
          path: '/sender-id/requests',
          icon: getIcon(lockFill),
          adminOnly: false,
          addDividerAfter: false,
        },
        {
          title: 'Sender-ID Inventory',
          path: '/sender-id/inventory',
          icon: getIcon(lockFill),
          adminOnly: false,
          addDividerAfter: false,
        }
      ]
    } : null,
    {
      title: 'Organisation',
      path: '/organisation',
      icon: <PeopleAltIcon/>,
      adminOnly: false,
      addDividerAfter: false,
    },
    {
      title: 'Compliance',
      path: '/two-factor-authentication',
      icon: <AssuredWorkloadIcon/>,
      adminOnly: false,
      addDividerAfter: false,
      children: [
        {
          title: 'Identity',
          path: '/identity',
          icon: <DashboardIcon/>,
          adminOnly: false,
          addDividerAfter: false,
        },
        {
          title: 'Addresses',
          path: '/addresses',
          icon: <DashboardIcon/>,
          adminOnly: false,
          addDividerAfter: false,
        }
      ]
    },
    displayCdr ? {
      title: 'CDRs',
      path: '/cdr',
      icon: <ListAltIcon/>,
      adminOnly: false,
      addDividerAfter: false,
    } : null,
    {
      title: 'Channels',
      path: '/channels',
      icon: <SettingsInputCompositeIcon/>,
      adminOnly: false,
      addDividerAfter: false,
    },
    {
      title: 'Reports',
      path: '/reports',
      icon: <SummarizeIcon/>,
      adminOnly: false,
      addDividerAfter: false,
    }
  ].filter(item => item !== null);


  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  useEffect(() => {
    console.log(store.manualUpdateTriggeredForPalette);
    if (store.logo) {
      setLogo(store.logo);
    }
  }, [store.manualUpdateTriggeredForPalette, store.logo]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box component={RouterLink} to="/" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'  , paddingY:3,  bgcolor: theme.palette["--secondary-color"]}}>
        <Logo sx={{width: "80%"}} src={logo} />
      </Box>
      <NavSection navConfig={appSidebarConfig} />

    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: theme.palette["--sidebar-background-color"]}
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: theme.palette["--sidebar-background-color"]
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
