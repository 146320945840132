import {
    MenuItem,
    FormControlLabel,
    Radio,
    FormControl,
    FormLabel,
    RadioGroup,
} from '@mui/material';
import i18n from 'src/i18n';

export const getSelectOptions = (options, discardItems = [], emptyText = i18n.t("common.form.any")) => {
    let items = emptyText ? [
        <MenuItem key="empty" value="0">{emptyText}</MenuItem>
    ] : [];
    options.map((data, idx) => {
        if (!(discardItems.includes(data.value))) {
            items.push(
                <MenuItem key={idx} value={data.value}>{data.label}</MenuItem>
            );
        }
    });
    return items;
};

export const getRadioButtonOptions = (options, disabled = false, discardItems = []) => {
    let items = [];
    options.forEach((data, idx) => {
        if (!(discardItems.includes(data.value))) {
            items.push(
                <FormControlLabel disabled={disabled} key={`${data.value}-${idx}`} value={data.value} control={<Radio />} label={data.label} />
            );
        }
    });
    return items;
};

export const getRadioButtonComponent = (options, props, title, direction = "row", discardItems = []) => {
    const disabled = props.disabled ? true : false;
    return (
        <FormControl sx={{ textAlign: "left" }} component="fieldset">
            <FormLabel disabled={disabled} color="secondary" >{title}</FormLabel>
            <RadioGroup {...props} row={direction === "row" ? true : false}>
                {getRadioButtonOptions(options, disabled, discardItems)}
            </RadioGroup>
        </FormControl>
    );
};

export const getItemDetails = (options, key = "value") => {
    let items = [];
    options.map((data, idx) => {
        items.push(options[key]);
    });
    return items;
};

export const getValueByLabel = (options, label) => {
    for (const idx in options) {
        if (options[idx].label == label) {
            return options[idx].value;
        }
    }
};

export const getColorByValue = (options, value) => {
    for (const idx in options) {
        if (options[idx].value == value) {
            return options[idx].color;
        }
    }
};

export const getLabelByValue = (options, value) => {
    for (const idx in options) {
        if (options[idx].value == value) {
            return options[idx].label;
        }
    }
};

export const DefaultPaginationData = {
    page: 0, rowsPerPage: 10, totalCount: 0
};


export const OrderStatus = () => [
    { value: '1', label: 'Requested', color: "--color-search" },
    { value: '2', label: 'Provisioning', color: "--color-primary" },
    { value: '3', label: 'Ready', color: "--color-success" },
    { value: '4', label: 'Pending Approval', color: "--color-primary" },
    { value: '5', label: 'Failed', color: "--color-danger" },
    { value: '6', label: 'Sent Back to Review', color: "--color-danger" },
    { value: '7', label: 'Rejected', color: "--color-danger" },
];



export const PreOrderStatus = () => [
    { value: '1', label: 'Open', color: "--color-primary" },
    { value: '2', label: 'Fulfilled', color: "--color-success" },
    { value: '3', label: 'Rejected', color: "--color-danger" },
    { value: '4', label: 'Partially Fulfilled', color: "--color-success" },
    { value: '5', label: 'Provisioning', color: "--color-warning" },
    { value: '6', label: 'Send Back To Review', color: "--color-warning" },
];


export const NumberStatus = () => [
    {value: '1', label: 'Requested'},
    {value: '2', label: 'Provisioning'},
    {value: '3', label: 'Ready'},
    {value: '4', label: 'Pending Approval'},
    {value: '5', label: 'Failed'},
    {value: '6', label: 'Sent Back to Review'},
    {value: '7', label: 'Rejected'}
];



export const TranslationStatus = () => [
    { value: '1', label: 'Pending' },
    { value: '2', label: 'Approved' },
    { value: '3', label: 'Rejected' },
    { value: '4', label: 'Finalized' },
    { value: '5', label: 'Cancelled' },
];

export const DefaultStatus = () => [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
];

export const DirectionType = () => [
    { value: "1", label: i18n.t('from') },
    { value: "2", label: i18n.t('to') },
];

export const SetType = () => [
    { value: "1", label: i18n.t('Mobile') },
    { value: "2", label: i18n.t('external') },
];

export const Capability = () => [
    {value: '1', label: 'Voice'},
    {value: '2', label: 'SMS'},
    {value: '3', label: 'Voice+SMS'},
];

export const sortByOptions = () => [
    {value: '1', label: 'Country'},
    {value: '2', label: 'Type'},
    {value: '3', label: 'Provider'},
];


export const NumberSearch = () => [
    {value: '1', label: 'Starts with'},
    {value: '2', label: 'Ends with'},
    {value: '3', label: 'Contains'},
];

export const DidType = () => [
    { value: "1", label: i18n.t('mobile') },
    { value: "2", label: i18n.t('geographic') },
    { value: "3", label: i18n.t("non-geographic") },
    { value: "4", label: i18n.t('toll-free') },
    { value: "5", label: i18n.t("uifn") },
];

export const NumberSearchType = () => [
    { value: "1", label: i18n.t('starts-with') },
    { value: "2", label: i18n.t('ends-with') },
    { value: "3", label: i18n.t('contains') },
];


export const StatusActiveInactive = () => [
    { value: "1", label: i18n.t("common.active") },
    { value: "2", label: i18n.t("common.inactive") },
];

export const NumberSelectionBehaviour = () => [
    { value: "1", label: i18n.t("common.prefer_sticky") },
    { value: "2", label: i18n.t("common.avoid_sticky") },
];
export const MembersStatus = () => [
    { value: "1", label: i18n.t('common.active'), color: "--color-success" },
    { value: "2", label: i18n.t('Deleted'), color: "--color-danger" },
    { value: "3", label: i18n.t('Suspended'), color: "--color-danger" },
    { value: "4", label: i18n.t('Invited'), color: "--color-warning" },
    { value: "99", label: i18n.t('Rejected'), color: "--color-danger" },
  ];
  

export const PortOrderStatus = () => [
    { value: '1', label: 'Requested', color: "--color-search" },
    { value: '3', label: 'In Progress', color: "--color-primary" },
    { value: '4', label: 'Completed', color: "--color-success" },
    { value: '5', label: 'Provisioning', color: "--color-primary" },
    { value: '6', label: 'Sent Back to Review', color: "--color-warning" },
    { value: '7', label: 'Rejected', color: "--color-danger" },
    { value: '2', label: 'Rejected', color: "--color-danger" },
    { value: '8', label: 'Invalid', color: "--color-danger" },  
];


export const PortInStatus = () => [
    { value: '1', label: 'Requested', color: "--color-search" },
    { value: '3', label: 'In Progress', color: "--color-primary" },
    { value: '4', label: 'Completed', color: "--color-success" },
    { value: '5', label: 'Provisioning', color: "--color-primary" },
    { value: '6', label: 'Sent Back to Review', color: "--color-warning" },
    { value: '7', label: 'Rejected', color: "--color-danger" },
    { value: '8', label: 'Invalid', color: "--color-danger" }
  ];

  

export const MatchType = () => [
    { value: "1", label: i18n.t('Prefix Based') },
    { value: "2", label: i18n.t('MCC/MNC - GT') },
]

export const IdentityType = () => [
    {"name": "Company", "value": 1 },
    {"name": "Personal", "value": 2},
];


export const IdentityVerificationStatus = () => [
    {"label": "Pending Verification", "value": 1 },
    {"label": "Verified", "value": 2},
];


export const PersonalIdentityType = () => [
    {"name": "Driving License", "value": 1 },
    {"name": "Passport", "value": 2},
    {"name": "ID Card", "value": 3},
    {"name": "Visa", "value": 4},
    {"name": "Other", "value": 5}
];

export const OrganisationIdentityType = () => [
    {"name": "Certificate of Incorporation", "value": 11 },
    {"name": "Companies House Registration", "value": 12},
    {"name": "Driving License", "value": 13},
    {"name": "Passport", "value": 14},
    {"name": "Identity Card", "value": 15}
];


export const SenderIDRequestStatus = () => [
    { value: '1', label: 'Requested', color: "--color-search" },
    { value: '2', label: 'In Progress', color: "--color-primary" },
    { value: '3', label: 'Sent Back to Review', color: "--color-warning" },
    { value: '4', label: 'Rejected', color: "--color-danger" },
    { value: '5', label: 'Cancelled', color: "--color-danger" },
    { value: '6', label: 'Completed', color: "--color-success" },
];

export const SenderIDStatus = () => [
    { value: '1', label: 'Ready', color: "--color-success" },
];

export const fontFamily = [
    {
        "fontFamily": "Roboto",
        "url": "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap",
        "type": "sans-serif"
      },
      {
        "fontFamily": "Open Sans",
        "url": "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap",
        "type": "sans-serif"
      },
       {
        "fontFamily": "Montserrat",
        "url": "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap",
        "type": "sans-serif"
      },
      {
        "fontFamily": "Poppins",
        "url": "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap",
        "type": "sans-serif"
      },
      {
        "fontFamily": "Lato",
        "url": "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap",
        "type": "sans-serif"
      },
     {
        "fontFamily": "Inter",
        "url": "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap",
        "type": "sans-serif"
      },
       {
        "fontFamily": "Noto Sans",
        "url": "https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap",
        "type": "sans-serif"
      },
     {
        "fontFamily": "Raleway",
        "url": "https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap",
        "type": "sans-serif"
      },
      {
        "fontFamily": "Nunito",
        "url": "https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap",
        "type": "sans-serif"
      },
      {
        "fontFamily": "Rubik",
        "url": "https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap",
        "type": "sans-serif"
      },
     {
        "fontFamily": "Ubuntu",
        "url": "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap",
        "type": "sans-serif"
      }
];