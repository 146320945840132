import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {
    Grid,
    Select,
    TextField,
    FormControl,
    InputLabel,
    Typography
} from '@mui/material';
import { BaseTable, TableFilterContainer } from 'src/components/table';
import SearchButton from 'src/components/buttons/SearchButton';
import BaseDialog from 'src/components/BaseDialog';
import { rowArrayToObject } from 'src/utils/Util';
import {
    DefaultPaginationData,
    getSelectOptions,
    getLabelByValue,
    NumberSearch, TranslationStatus
} from 'src/constants/index';
import { SearchNumberForm } from './Forms';
import {ChangeRequestService} from 'src/api/services';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { useTranslation } from 'react-i18next';
import dayjs from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import TranslationInfoForm from "../endpoints/Forms/TranslationInfoForm";
import BaseCard from 'src/components/BaseCard';
import BaseTextField from 'src/components/BaseTextField';
import BaseSelect from 'src/components/BaseSelect';
import BaseDateTimePicker from 'src/components/BaseDateTimePicker';


const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const DIALOG_PROPERTIES = {
    fullWidth: true,
    maxWidth: "lg",
    scroll: "body",
}

const TitleStyle = styled(Typography)(({ theme }) => ({
    color: theme.palette.background.contrastText,
    display: "inline"
}));

export default function Deallocations() {
    const { t } = useTranslation();
    const TABLE_HEAD = [
        { key: "id", label: "#" },
        { key: "did", label: "DID" },
        { key: "requested_at", label: "Requested At" },
        { key: "scheduled_at", label: "Scheduled At" },
        { key: "finalized_at", label: "Finalized At" },
        { key: "customer_notes", label: "Customer Notes" },
        { key: "change_status", label: "Status" },
    ];

    const TABLE_FIELD_MAPPING = {
        id: { key: "id", cellComponentType: "th", index: 0 },
        did: { key: "did", index: 1 },
        requested_at: { key: "requested_at", index: 2 },
        scheduled_at: { key: "scheduled_at", index: 3 },
        finalized_at: { key: "finalized_at", index: 4 },
        customer_notes: { key: "customer_notes", index: 5 },
        change_status: { key: "change_status", index: 6 },
    };

    const [openSnackbar, setSnackbarStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [openDeallocateModal, setDeallocateModalStatus] = useState(false);
    const [openSearchNumbersDialog, setSearchNumbersDialogStatus] = useState(false);
    const [data, setData] = useState([]);

    const [selectedRow, setSelectedRow] = useState({});
    const [paginationData, setPaginationData] = useState(DefaultPaginationData);
    const [totalCount, setTotalCount] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [filterNumberSearch, setFilterNumberSearch] = useState(3);
    const [filterNumber, setNumber] = useState("");
    const [openDeallocations, setOpenDeallocations] = useState(false);

    const [filterStart, setFilterStart] = useState(dayjs(new Date()).startOf('day'));
    const [filterEnd, setFilterEnd] = useState(dayjs(new Date()).endOf('day'));

    const fetchDeallocations = () => {
        const params = {
            number_regex: filterNumberSearch ? filterNumberSearch : undefined,
            number_value: filterNumber ? filterNumber : undefined,
            start_datetime:filterStart.format('YYYY-MM-DDTHH:mm:ss'),
            end_datetime:filterEnd.format('YYYY-MM-DDTHH:mm:ss'),
            page: paginationData.page + 1,
            size: paginationData.rowsPerPage,
        };
        setLoadingData(true);
        ChangeRequestService.getDeallocationRequests(params)
            .then((response) => {
                let items = [];
                setTotalCount(response.data.data.count);
                for (const idx in response.data.data.items) {
                    let item = new Array(TABLE_HEAD.length - 1).fill({});
                    Object.entries(response.data.data.items[idx]).forEach(([key, value]) => {
                        if (key in TABLE_FIELD_MAPPING) {
                            item[TABLE_FIELD_MAPPING[key].index] = {
                                ...TABLE_FIELD_MAPPING[key],
                                value: value,
                            };
                        }
                    });
                    items.push(item);
                }
                setData(items);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    useEffect(() => {
        fetchDeallocations();
        return () => {
            setData([]);
        }
    }, [paginationData]);


    const formatRowData = (rowData) => {
        let formatted = [];
        rowData.map((d, idx) => {
            if (d.key === "change_status") {
                formatted.push({
                    ...d,
                    value: getLabelByValue(TranslationStatus(), d.value.toString()),
                });
            } else if (d.key === "requested_at" || d.key === "scheduled_at" || d.key === "finalized_at") {
                formatted.push({
                    ...d,
                    value: d.value ? dayjs(d.value).format('DD/MM/YYYY HH:mm') : "",
                });
            } else {
                formatted.push(d);
            }
        })
        return formatted;
    }

    const modalHandler = (modalType, index = undefined) => {
        if (modalType === "openDeallocations") {
            setOpenDeallocations(!openDeallocations);
        }
        if (index) { setSelectedRow(rowArrayToObject(data[index])) };
    }

    return (
        <>
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setSnackbarStatus} />
            <BaseDialog title={t('search-numbers')} dialogProperties={DIALOG_PROPERTIES} closeCallback={fetchDeallocations} open={openSearchNumbersDialog} setOpen={setSearchNumbersDialogStatus} children={<SearchNumberForm successCallback={fetchDeallocations} formType="add" formData={{}} setModalStatus={setSearchNumbersDialogStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseDialog title={"Add SIP Trunk"} dialogProperties={DIALOG_PROPERTIES} closeCallback={fetchDeallocations} open={openDeallocations} setOpen={setOpenDeallocations} children={<TranslationInfoForm successCallback={fetchDeallocations} formType="add" formData={selectedRow} setModalStatus={setSearchNumbersDialogStatus} setSnackbarStatus={setSnackbarStatus} setMessage={setMessage} />} />
            <BaseCard>
            <TableFilterContainer>
                <Grid sx={{ alignItems: "center" }} container spacing={4}>


                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <BaseDateTimePicker onChange={(date)=> setFilterStart(date)} value={filterStart} label="Start Date-Time" />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>

                    <Grid item md={3} xs={12}>
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <BaseDateTimePicker onChange={(date)=> setFilterEnd(date)} value={filterEnd} label="End Date-Time" />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>

                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="filter-status-label">{'Number Search'}</InputLabel>
                            <BaseSelect
                                label={'number-search'}
                                labelId="filter-number-search-label"
                                name="number-search"
                                color="secondary"
                                value={filterNumberSearch}
                                onChange={event => { setFilterNumberSearch(event.target.value) }}
                            >
                                {getSelectOptions(NumberSearch(), ['0'], false)}
                            </BaseSelect>
                        </FormControl>
                    </Grid>
                    <Grid item md={1.6} xs={12}>
                        <FormControl fullWidth>
                            <BaseTextField
                                value={filterNumber}
                                label={t('number-0')}
                                name="number"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                onChange={setNumber}
                            />
                        </FormControl>
                    </Grid>

                    <SearchButtonContainer item md={2} xs={12}>
                        <SearchButton onClick={() => { fetchDeallocations() }} />
                    </SearchButtonContainer>
                </Grid>
                <br />
                <SearchButtonContainer item md={12} xs={12}>
                    <strong>Results:</strong>   <TitleStyle>Total <strong>{totalCount}</strong> records found.
                </TitleStyle>
                    <br />

                </SearchButtonContainer>

            </TableFilterContainer>
            </BaseCard>
            <br />

            <BaseTable
                head={TABLE_HEAD}
                data={[...data].map((d, idx) => { return formatRowData(d); })}
                pagination={{ paginationData: { ...paginationData, totalCount: totalCount }, setPaginationData: setPaginationData }}
                loadingData={loadingData}
            />
        </>
    );
}
