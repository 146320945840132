import React from 'react';
import { useState } from 'react';
// material
import {
    Stack, Select,
    TextField,
    FormControl, RadioGroup, FormControlLabel, Radio, FormLabel, Typography, Grid, Box, Button, useTheme
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// app
import { useTranslation } from 'react-i18next';
import {IdentityService} from 'src/api/services';
import BaseButton from 'src/components/buttons/BaseButton';
import {styled} from "@mui/system";
import LinearProgress, {linearProgressClasses, LinearProgressProps} from "@mui/material/LinearProgress";
import {IdentityType, IdentityTypeSelect, IdentityVerificationStatus, PersonalIdentityType} from "../../../../constants";
import {getSelectOptions, OrganisationIdentityType} from "../../../../constants";
import BaseSelect from 'src/components/BaseSelect';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}
export default function AddPersonalIdentityForm({ setModalStatus, setSnackbarStatus, setMessage, successCallback, countries }) {

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [identityType, setIdentityType] = useState(1);
    const [itentityName, setIdentityName] = useState('');
    const [externalId, setExternalId] = useState('');
    const [email, setEmail] = useState('');
    const [verificationStatus, setVerificationStatus] = useState(1);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    

    const addIdentity =() => {
        
        let payload = {
            identity_name: itentityName,
            compliancy_type: identityType,
            contact_email: email,
            external_id: externalId,
            first_name: firstName,
            last_name: lastName,
            company_name: companyName,
        }

        IdentityService.createIdentity(payload)
        .then((response) => {
            if (response.data.meta.code === 200) {
                setMessage(t('Identity added successfully'));
                setSnackbarStatus(true);
                setModalStatus(false);
                setLoading(false);
                successCallback();
            } else {
                throw (t("could-not-be-added"));
            }
        })
        .catch((err) => {
            setMessage(t('could-not-be-added', {name: firstName}));
            setSnackbarStatus(true);
            setModalStatus(false);
            setLoading(false);
        });
    }

    const theme = useTheme();


    return (
        <Stack sx={{display: "flex"}} spacing={2}>
                <Typography sx={{ textAlign: 'left', color: theme.palette['--main-text-color'] }}>
                    {'Identity Type'}
                </Typography>

                <RadioGroup row
                            name="controlled-radio-buttons-group"
                            value={identityType}
                            onChange={(event) => setIdentityType(event.target.value)}
                >
                    {IdentityType().map((option) => (
                        <FormControlLabel sx={{color: theme.palette['--main-text-color']}} value={option.value} control={<Radio/>} label={option.name}/>
                    ))}
                </RadioGroup>

            <TextField
                value={itentityName}
                label={'Identity Name'}
                name="firstName"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={event => { setIdentityName(event.target.value) }}
            />
            
            <TextField
                value={externalId}
                label={'External/Site ID'}
                name="lastName"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={event => { setExternalId(event.target.value) }}
            />

            <TextField
                value={email}
                label={'Contact E-mail'}
                name="lastName"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={event => { setEmail(event.target.value) }}
            />
            
            {identityType == 2 ? (<>
                <TextField
                value={firstName}
                label={'First Name'}
                name="telephone"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={event => { setFirstName(event.target.value) }}
            />      
            
            <TextField
                value={lastName}
                label={'Last Name'}
                name="telephone"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={event => { setLastName(event.target.value) }}
            />
            </>) : (<>
                <TextField
                value={companyName}
                label={'Company Name'}
                name="telephone"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={event => { setCompanyName(event.target.value) }}
            />
            </>)}


            <Stack sx={{ display: "block", alignItems: "right"  }} direction="row" spacing={2}>
                <BaseButton
                    type="submit"
                    variant="contained"
                    disabled={loading}
                    onClick={() => setModalStatus(false)}
                    >
                    {t('cancel')}
                </BaseButton>
                <BaseButton
                    type="submit"
                    color="--color-primary"
                    variant="contained"
                    loading={loading}
                    onClick={() => addIdentity()}
                    >
                    {t('Save')}
                </BaseButton>
            </Stack>
        </Stack>
  );
}
