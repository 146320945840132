import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {
    Grid,
    Select,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Typography, Box, Button, Divider,
    FormControlLabel,
    Radio,
    RadioGroup
} from '@mui/material';
import { TableFilterContainer } from 'src/components/table';
import {
    DefaultPaginationData,
    getSelectOptions
} from 'src/constants/index';
import {CommonService, IdentityService, NumberService, PortingService} from 'src/api/services';
import BaseSnackbar from 'src/components/BaseSnackbar';
import { useTranslation } from 'react-i18next';
import { makeStyles } from "@mui/styles";
import LinearProgress, {linearProgressClasses, LinearProgressProps} from "@mui/material/LinearProgress";
import {useNavigate} from "react-router-dom";
import BaseButton from "../../../../components/buttons/BaseButton";
import { useTheme } from '@mui/material/styles';
import BaseSelect from "../../../../components/BaseSelect";
import {numberRequest} from "../../../../api/services/Numbers";
import {tr} from "date-fns/locale";
import BaseTextField from "../../../../components/BaseTextField";
import { LoadingButton } from '@mui/lab';
import { set } from 'lodash';
import BaseSearchableSelect from 'src/components/BaseSearchableSelect';

const useStyles = makeStyles((theme) => ({
    selectedCircle: {
        width: 30,
        height: 30,
        borderRadius: "50%",
        backgroundColor: "black",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 15,
    },
    circle: {
        width: 30,
        height: 30,
        borderRadius: "50%",
        backgroundColor: "darkgray",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 15,
    },
}));

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}


const BorderLinearProgress = styled(LinearProgressWithLabel)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "primary",
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: "primary",
    },
}));

export default function NumberRequestForm({formData, extraData, successCallback, setSnackbarStatus, setMessage}) {
    const { t } = useTranslation();

    const [page, setSelectedPageState] = useState(1);
    const [countries, setCountries] = useState([]);
    const [numberTypes, setNumberTypes] = useState([]);

    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [country, setCountry] = useState("");
    const [taxId, setTaxId] = useState("");

    const setSelectedPage = (page) => {
        if(complianceRequirements != undefined && complianceRequirements.restricted == true){
            setMessage("This product is restricted for this identity");
            setSnackbarStatus(true);
            return;
        }
        setSelectedPageState(page);
    }

    const [filterCountry, setFilterCountry] = useState("");
    const [filterEnabledCountry, setFilterEnabledCountry] = useState("");
    const [filterNumberTypes, setFilterNumberTypes] = useState(0);
    const [textFieldDoc, setTextFieldDoc] = useState({});
    const [isDocsFetched, setIsDocsFetched] = useState(false);

    const [filterRoutingType, setFilterRoutingType] = useState(0);
    const [filterVoiceEndpoint, setFilterVoiceEndpoint] = useState(0);
    const [loadingData, setLoadingData] = useState(false)

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();


    const [translationInfo, setTranslationInfo] = useState(
        Array.from({ length: extraData['quantity'] }, () => ({
            landline: '',
            mobile: '',
            payphone: ''
        }))
    );




    const quantity = extraData['quantity'];

    const textFields = [];

    for (let i = 0; i < quantity; i++) {
        textFields.push(
            <Grid key={i} container justifyContent="start" alignContent="center" alignItems="center" paddingBottom={2} spacing={2}>
                <Grid item md={3} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            label="Landline"
                            variant="outlined"
                            value={translationInfo[i]?.landline || ''}
                            onChange={(e) => handleTranslationChange(i, 'landline', e.target.value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            label="Mobile"
                            variant="outlined"
                            value={translationInfo[i]?.mobile || ''}
                            onChange={(e) => handleTranslationChange(i, 'mobile', e.target.value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={3} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            label="Payphone"
                            variant="outlined"
                            value={translationInfo[i]?.payphone || ''}
                            onChange={(e) => handleTranslationChange(i, 'payphone', e.target.value)}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        );
    }

    const classes = useStyles();
    const theme = useTheme();


    const initialTextFields = {
        "end_user_name": "",
        "billing_telephone": "",
        "account_number": "",
        "address": "",
        "comments": "",
    };

    const [textFieldsData, setTextFieldsData] = useState(initialTextFields);

    const handleTextFieldChange = (key, value) => {
        setTextFieldsData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
        console.log(textFieldsData);
    };


    const renderTextFields = () => {
        return textFieldDoc?.data?.text_fields?.map((field) => (
            <Grid item xs={12} key={field.key}>
                <TextField
                    label={field.label}
                    value={textFieldsData[field.key]}
                    onChange={(e) => handleTextFieldChange(field.key, e.target.value)}
                    fullWidth
                />
            </Grid>
        ));
    };

    const [uploadProgressMap, setUploadProgressMap] = useState({});
    const [uploadedFileInfo, setUploadedFileInfo] = useState([]);
    const [selectedFile, setSelectedFile] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [endpoints, setEndpoints] = useState([]);
    const [endpoint, setEndpoint] = useState(undefined);
    const [routingType, setRoutingType] = useState(2);

    const handleFileChange = (label) => (event) => {
        const selectedFile = event.target.files[0];
        const fileWithLabel = { file: selectedFile, label: label };

        setSelectedFile((prevSelectedFiles) => [...prevSelectedFiles, fileWithLabel]);
    };

    const updateUploadProgress = (label, percentage) => {
        setUploadProgressMap((prevProgressMap) => ({
            ...prevProgressMap,
            [label]: percentage,
        }));
    };

    const uploadFiles = async () => {
        setIsUploading(true);
        setMessage("Uploading Files");
        setSnackbarStatus(true);
        try {
            const promises = selectedFile.map((file) => {
                const form = new FormData();
                form.append('files', file.file);
                const onUploadProgress =   {
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let precentage = Math.floor((loaded * 100) / total);
                        updateUploadProgress(file.label, precentage);
                    }
                }

                return NumberService.uploadFilesBuyNumber(form, onUploadProgress)
                    .then((response) => {
                        if (response.status === 200) {
                            if (response.data.meta.code === 200) {
                                setMessage("File uploaded");
                                setSnackbarStatus(true);

                                const uploadedFiles = response.data.data;
                                const guidsByLabel = [];

                                uploadedFiles.forEach((uploaded) => {
                                    guidsByLabel.push({
                                        guid: uploaded.guid,
                                        document: file.label,
                                    });
                                });
                                return guidsByLabel;
                            }
                        } else {
                            throw new Error("Error uploading file");
                        }
                    })
                    .catch((err) => {
                        setMessage(err.toString());
                        setSnackbarStatus(true);
                    });
            });

            const results = await Promise.all(promises);
            const mergedGuidsByLabel = results.reduce((acc, curr) => acc.concat(curr), []);

            setUploadedFileInfo(mergedGuidsByLabel);
            console.log(mergedGuidsByLabel);
            setMessage("All Files Uploaded");
            setSnackbarStatus(true);
        } catch (error) {
            setMessage(error.message.toString());
            setSnackbarStatus(true);
        } finally {
            setIsUploading(false);
        }
    };


    const [purchaseFor, setPurchaseFor] = useState("Individual");
    const [interconnections, setInterconnections] = useState([]);

    const [activePage, setActivePage] = useState(0);

    const removeFile = (fileName) => {
        setSelectedFile(prevFiles => prevFiles.filter(file => file.file.name !== fileName));
    };


    const changeIsIndividual = () => {
        if(purchaseFor == "Individual"){
            setPurchaseFor("Company")
        }else{
            setPurchaseFor("Individual")
        }
    }

    const getEnabledCountries = () => {
        CommonService.getCountries({outbound_enabled:true})
            .then((response) => {
                let items = [];
                response.data.data.map((item) => {
                    items.push({ iso_code_2digit: item.iso_code_2digit, name: item.name });
                });
                setCountries(items);
                if (items.length > 0) {
                    setFilterEnabledCountry(items[0].iso_code_2digit);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const fetchInterconnection = () => {
        setLoadingData(true);
        NumberService.listInterconnection()
            .then((response) => {
                setInterconnections(response.data.data.items);
                setEndpoint(response.data.data.items[0]['id']);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoadingData(false);
            })
    }

    const handleTranslationChange = (index, key, value) => {
        setTranslationInfo(prevTranslationInfo => {
            const updatedTranslations = [...prevTranslationInfo];
            updatedTranslations[index][key] = value;
            return updatedTranslations;
        });
    };

    function isAnyValueUndefined(obj) {
        for (const key in obj) {
                if (obj[key] == undefined) {
                    return true;
                }
        }
        return false;
    }





    useEffect(() => {
        getEnabledCountries();
        fetchInterconnection();
    }, []);

    //--------------------------------------------------------------------------------


    const [filterIdentity, setFilterIdentity] = useState(undefined);
    const [identities, setIdentities] = useState([]);

    const [complianceRequirements, setComplianceRequirements] = useState();

    const [textFieldValues, setTextFieldValues] = useState({});

    const [addresses, setAddresses] = useState([]);
    const [filterAddress, setFilterAddress] = useState(undefined);

    const [fileData, setFileData] = useState([]);

    const handleFileChangeCompl = (event, id) => {
        const file = event.target.files[0];

        const selectedFile = event.target.files[0];


        let allowedExtensions = complianceRequirements.fields.filter((item) => item.id == id)[0].allowed_extensions;


        allowedExtensions = allowedExtensions.filter((item) => item != "");


        if(allowedExtensions.length > 0){
            if(allowedExtensions.indexOf(selectedFile.name.split('.').pop()) == -1){
                setMessage("Only following file extensions allowed: " + allowedExtensions.join(", "));
                setSnackbarStatus(true);
                event.target.value = null;
                return;
            }
        }

        const reader = new FileReader();
  
          reader.readAsDataURL(selectedFile);
  
          reader.onload = () => {
              const base64 = reader.result;
              const file = {
                  id: id,
                  data_file: {
                      data: base64,
                      name: selectedFile.name,
                      extension: selectedFile.name.split('.').pop(),
                      mime_type: selectedFile.type
                  }
              };
              setFileData((prevFiles) => [...prevFiles.filter(file => file.id !== id), file]);
          }

    };


    const handleTextFieldChangeCompl = (event, id) => {
        setTextFieldValues((prevValues) => ({
            ...prevValues,
            [id]: event,
        }));
    };


    const fetchIdentities = () => {
        const params = {
            pagination:2
        };
        IdentityService.listIdentities(params)
            .then((response) => {
                if (response.status === 200) {
                    const items = [];
                    response.data.data.forEach((item) => {
                        items.push({ code: item["id"], name: item["identity_name"], label: item["identity_name"], value: item["id"], compliancy_type_id: item["compliancy_type_id"] });
                    }
                    )
                    setIdentities(items);
                } else {
                    throw new Error("Error fetching data");
                }
                
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
            })
    };

    const fetchAddresses = () => {
        const params = {
            pagination:2
        };
        IdentityService.listAddresses(params)
            .then((response) => {
                if (response.status === 200) {
                    const items = [];
                    response.data.data.items.forEach((item) => {
                        items.push({ value: item["id"], address_line_1: item["address_line_1"], city: item["city"], country: item["country"], label: item["address_line_1"] + ", " + item["city"] + ", " + item["country"] });
                    }
                    )
                    setAddresses(items);
                } else {
                    throw new Error("Error fetching data");
                }
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
            })
    };

    const fetchComplianceRequirementsProduct = () => {
        setComplianceRequirements(undefined);

        const params = {
            identity_id: filterIdentity,
            product_id: formData.id
        };

        IdentityService.fetchComplianceRequirements(params)
            .then((response) => {
                if (response.status === 200) {
                    setComplianceRequirements(response.data.data);
                    if(response.data.data != null && response.data.data.restricted == true){
                        setMessage("This product is restricted for this identity");
                        setSnackbarStatus(true);
                    }
                } else {
                    throw new Error("Error fetching data");
                }
                
            })
            .catch((err) => {
                setMessage(err);
                setSnackbarStatus(true);
            })
            .finally(() => {
            })
    };

    useEffect(() => {
        fetchIdentities();
        fetchAddresses();
    }, []);

    
    useEffect(() => {
        fetchComplianceRequirementsProduct();
    }, [filterIdentity]);
    
    const generatePayloadFromState = () => {
        const payload = {
            sell_product_id: formData.id,

            identity_id: filterIdentity,
            address_id: filterAddress,
            compliances: [],
        };

        fileData.forEach((item) => {
            payload.compliances.push({
                'id': item.id,
                'type': 1,
                'data_file': {
                    'name': item.data_file.name,
                    'extension': item.data_file.extension,
                    'mime_type': item.data_file.mime_type,
                    'data': item.data_file.data
                }
            });
        }
        );


        Object.keys(textFieldValues).forEach((key) => {
            payload.compliances.push({
                'id': parseInt(key),
                'type': 2,
                'data_text': {
                    'value': textFieldValues[key]
                }
            });
        }
        );

        console.log(payload);

        return payload;
    };

    const changePage = (page) => {
        if(filterIdentity == undefined || filterIdentity == null || filterAddress == undefined || filterAddress == null){
            setMessage("Please select an identity and address");
            setSnackbarStatus(true);
            return;
        }

        const payload = generatePayloadFromState();

        let missingFields = [];

        if(complianceRequirements != undefined && complianceRequirements.fields.length > 0){
            complianceRequirements.fields.forEach((item) => {
                if(item.field_status == 2 && item.field_type == 1){
                    let found = false;
                    payload.compliances.forEach((compliance) => {
                        if(compliance.id == item.id && compliance.type == 1){
                            found = true;
                        }
                    });
                    if(!found){
                        missingFields.push(item.display_text);
                    }
                }else if(item.field_status == 2 && item.field_type == 2){
                    let found = false;
                    payload.compliances.forEach((compliance) => {
                        if(compliance.id == item.id && compliance.type == 2){
                            found = true;
                        }
                    });
                    if(!found){
                        missingFields.push(item.display_text);
                    }
                }
            });
        }

        
        if(missingFields.length > 0){
            setMessage("Please fill in the following fields: " + missingFields.join(", "));
            setSnackbarStatus(true);
            return;
        }


        setSelectedPage(page);
    }

    const generateAndUpload = () => {
        setLoading(true);
        if(filterIdentity == undefined || filterIdentity == null || filterAddress == undefined || filterAddress == null){
            setMessage("Please select an identity and address");
            setSnackbarStatus(true);
            return;
        }

        if(complianceRequirements != undefined && complianceRequirements.restricted == true){
            setMessage("This product is restricted for this identity");
            setSnackbarStatus(true);
            return;
        }

        const payload = generatePayloadFromState();

        let missingFields = [];

        if(complianceRequirements != undefined && complianceRequirements.fields.length > 0){
            complianceRequirements.fields.forEach((item) => {
                if(item.field_status == 2 && item.field_type == 1){
                    let found = false;
                    payload.compliances.forEach((compliance) => {
                        if(compliance.id == item.id && compliance.type == 1){
                            found = true;
                        }
                    });
                    if(!found){
                        missingFields.push(item.display_text);
                    }
                }else if(item.field_status == 2 && item.field_type == 2){
                    let found = false;
                    payload.compliances.forEach((compliance) => {
                        if(compliance.id == item.id && compliance.type == 2){
                            found = true;
                        }
                    });
                    if(!found){
                        missingFields.push(item.display_text);
                    }
                }
            });
        }

        
        if(missingFields.length > 0){
            setMessage("Please fill in the following fields: " + missingFields.join(", "));
            setSnackbarStatus(true);
            return;
        }


        if(extraData['city'] != undefined){
            extraData['note'] = extraData['note'] + " City: " + extraData['city'].label;
            extraData['city'] = extraData['city'].value;
        }

        if(extraData['state'] != undefined){
            extraData['note'] = extraData['note'] + " State: " + extraData['state'].label;
            extraData['state'] = extraData['state'].value;
        }
        
        let newData = {
            ...extraData,
            ...payload,
            sell_product_id: formData.id,
            properties: {
                translations: [],
                advanced_translations: translationInfo,
                trunk_id: endpoint,
                route_type: routingType,
                provider_code: extraData['provider_code'],
            }
        };

        delete newData.provider_code;

        if (routingType == 3){
            newData = {...newData, properties: {...newData['properties'], tdm_country: filterEnabledCountry}}
            delete newData.properties.trunk_id;
        }

        if (routingType == 2){
            delete newData.properties.tdm_country;
        }


        NumberService.numberRequest(newData)
        .then((response) => {
            if (response.data != undefined && response.data.status == true) {
                setMessage("Pre-order has been successfully submitted");
                setSnackbarStatus(true);
                successCallback();
            } else {
                throw {message:"Request could not be sent", response:response};
            }
            setLoading(false);
        })
        .catch((err) => {
            setMessage("An error occured.");
            setSnackbarStatus(true);
        })
    };


    return (
        <>
        <TableFilterContainer>
            {page == 1 && (<>
                <Grid item xs={4} container alignItems="center" justifyContent="flex-start">
                        <FormControl fullWidth>
                        <BaseSearchableSelect label={"Identity"} options={identities} state={filterIdentity} setState={setFilterIdentity} />
                        </FormControl>
                        <Typography sx={{ textAlign: 'left', marginTop: 1 }}>
                                    {identities.find(item => item.value == filterIdentity) ? identities.find(item => item.value == filterIdentity).compliancy_type_id == 1 ? "Company Identity Selected" : "Personal Identity Selected" : ""}
                        </Typography>
                    <br/>
                </Grid>
                <br/>
                <Grid item xs={4} container alignItems="center" justifyContent="flex-start">
                        <FormControl fullWidth>
                            <BaseSearchableSelect label={"Address"} options={addresses} state={filterAddress} setState={setFilterAddress} />
                        </FormControl>
                    <br/>
                </Grid>

                <br/>
                    {complianceRequirements != undefined &&  complianceRequirements.descriptions != undefined && complianceRequirements.descriptions != null && (
                         <>
                         <fieldset style={{ border: '1px solid #ccc', padding: 30, borderRadius: 10, textAlign: 'left' }}>
                                            <legend>Descriptions</legend>
                        <>
                            {complianceRequirements.descriptions.map((item, index) => (
                            <>
                                <Grid direction={'column'} alignItems="left">
                                        <Typography sx={{ textAlign: 'left' }}>
                                        {item.description_header}</Typography>
                                            {item.descriptions.map((descr, index) => (
                                                <li key={index} style={{textAlign: 'left'}}>{descr}</li>
                                            ))}
                                    </Grid>
                                    </>
                                    ))}

                            </>  
                    </fieldset>

                    </>
                        )}

                        <br/>

                        {complianceRequirements != undefined && complianceRequirements.fields.length > 0 ? (
                         <>
                       
                         <fieldset style={{ border: '1px solid #ccc', padding: 10, borderRadius: 10, textAlign: 'left' }}>
                                            <legend>Requirements</legend>
                         {complianceRequirements.fields.map((item, index) => (
                            <>
                            <br/>
                            <Grid container direction={'row'} alignItems="left" justifyContent="flex-start">
                                    <Grid item xs={2} container alignItems="center" justifyContent="flex-start">

                                    <Typography sx={{ textAlign: 'left' }}>
                                        {item.display_text}</Typography>
                                        </Grid>

                                        <Grid item xs={4} container alignItems="center" justifyContent="flex-start">


                                    {item.field_status == 0 && (
                                        <Typography sx={{ textAlign: 'left', color: 'orange' }}>
                                            {'Pending validation'}
                                        </Typography>)
                                    }

                                    {item.field_status == 1 && (
                                        <Typography sx={{ textAlign: 'left', color: 'green' }}>
                                            {'Already verified'}
                                        </Typography>)
                                    }


                                    {item.field_status == 2 && item.field_type == 1 && (
                                            <>
                                            <Grid container alignItems="center" justifyContent="left">
                                                <BaseButton>
                                                    <input
                                                        accept={'.' + item.allowed_extensions.join(', .')}
                                                        id={item.id}
                                                        type="file"
                                                        onChange={(event) => handleFileChangeCompl(event, item.id)}
                                                        style={{ height: 35, alignContent: 'center' }}
                                                    />
                                                </BaseButton>
                                            </Grid>
                                        </>
                                    )
                                    }

                                    {item.field_status == 2 && item.field_type == 2 && (
                                             <BaseTextField
                                             sx={{ width: '100%' }}
                                                 key={item.id}
                                                 value={null}
                                                 name="number"
                                                 margin="normal"
                                                 variant="outlined"
                                                 color="secondary"
                                                 onChange={event => handleTextFieldChangeCompl(event, item.id)}
                                             />
                                    )
                                    }

                                    </Grid>


                                    <Grid item xs={3} container alignItems="center" justifyContent="center">
                                    {item.field_status == 2 && item.reject_comment != undefined && item.reject_comment != null && item.reject_comment != "" && (
                                    
                                    <Typography sx={{ textAlign: 'left', color: 'red' }}>
                                        {item.reject_comment}
                                    </Typography>)
                                    }  

                                    </Grid>

                                    <br/>
                                    </Grid>
                                 
                                    </>
                                    
                          ))}
                          <br/>
                             <Typography sx={{ textAlign: 'left', color: 'red'}}>
                                        {"* All fields are required"}  
                                    </Typography>
                   
                            
                            </fieldset>

                    </>
                        ) : (
                            <></>
                        )}
                        <br/>
                        <Grid sx={{ alignItems: "center" }} justifyContent="right" container spacing={0}>
                            <SearchButtonContainer item >
                                <BaseButton
                                    label={'Cancel'}
                                    onClick={() => successCallback()}
                                />
                                <BaseButton
                                    label={'Next'}   sx={{ marginLeft: 1 }}
                                    onClick={() => changePage(2)}  color={"--color-search"}
                                />
                            </SearchButtonContainer>
                        </Grid>
                            
            </>)} 
            {page == 2 && (<>
            
                <TableFilterContainer>
                <Grid container spacing={2}>

                    <Grid item xs={4}>

                <Box sx={{mb:1}}/>

                <FormControl fullWidth>
                    <InputLabel id="filter-provider-label">{"Routing Type"}</InputLabel>
                    <BaseSelect
                        label={"Routing Type"}
                        labelId="filter-provider-label"
                        name="provider"
                        color="secondary"
                        value={routingType}
                        onChange={event => { setRoutingType(event.target.value) }}
                    >
                        {[
                            {value: 2, text: 'IP'},
                            {value: 3, text: 'PSTN'},
                        ].map((item, index) => {
                            return <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>;
                        })}
                    </BaseSelect>
                </FormControl>
                <Box sx={{mb:2}}/>

                {routingType == 2 ? (<FormControl fullWidth>
                    <InputLabel id="filter-provider-label">{"Voice Endpoint"}</InputLabel>
                    <BaseSelect
                        label={"Voice Endpoint"}
                        labelId="filter-provider-label"
                        name="provider"
                        color="secondary"
                        value={endpoint}
                        onChange={event => { setEndpoint(event.target.value) }}
                    >
                        {interconnections.map((item, index) => {
                            return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>;
                        })}
                    </BaseSelect>
                </FormControl>) :  (<FormControl fullWidth>
                    <InputLabel id="filter-provider-label">{"Enabled Countries"}</InputLabel>
                    <BaseSelect
                        label={"Enabled Countries"}
                        labelId="filter-country-label"
                        name="country"
                        color="secondary"
                        value={filterEnabledCountry}
                        onChange={event => { setFilterEnabledCountry(event.target.value) }}
                    >
                        {countries.map((item, index) => {
                            return <MenuItem key={item.iso_code_2digit} value={item.iso_code_2digit}>{item.name}</MenuItem>;
                        })}
                    </BaseSelect>
                </FormControl>)}


                    </Grid>
                    <Grid sx={{ alignItems: "center" }} justifyContent="right" container spacing={0}>
                        <SearchButtonContainer item >
                            <BaseButton
                                label={'Cancel'} sx={{ marginRight: 1 }}
                                onClick={() => successCallback()}
                            />
                            <BaseButton
                                label={'Next'}
                                onClick={() => setSelectedPage(3)  } color={"--color-search"}
                            />

                        </SearchButtonContainer>
                    </Grid>
                </Grid>
                 </TableFilterContainer>

            </>)}
            {page == 3 && (<>
                <TableFilterContainer>
                    <Grid container justifyContent="start" alignContent="start" alignItems="start" paddingBottom={2} spacing={2}>
                        <Grid item>
                            <Typography variant="h6">
                                {"Translations"}
                            </Typography>
                        </Grid>

                    </Grid>

                    <div>
                        {textFields}
                    </div>
                    <Grid sx={{ alignItems: "center" }} justifyContent="right" container spacing={0}>
                        <SearchButtonContainer item >
                            <BaseButton
                                label={'Cancel'}   sx={{ marginRight: 1 }}
                                onClick={() => successCallback()  }
                            />
                          
                            <LoadingButton onClick={() => generateAndUpload()} loading={loading} color="primary" margin="normal" variant="contained" component="span">
                                {'Submit'}
                            </LoadingButton>
                        </SearchButtonContainer>
                    </Grid>
                </TableFilterContainer>
            </>)}
            {page == 4 && (<></>)}
        </TableFilterContainer>
        </>
    );
}
