// ----------------------------------------------------------------------
import { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import {
    Grid,
    TextField,
    FormControl
} from '@mui/material';
import { TableFilterContainer } from 'src/components/table';
import { useTranslation } from 'react-i18next';
import BaseButton from "../../../../components/buttons/BaseButton";

import {makeStyles} from '@mui/styles';
import dayjs from "dayjs";
import { TranslationStatus, getLabelByValue } from 'src/constants';

const SearchButtonContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: "left",
    },
    [theme.breakpoints.down('md')]: {
        textAlign: "right",
    },
}));

const useStyles = makeStyles((theme) => ({
    divider: {
        borderTop: `1px solid ${theme.palette.divider}`,
    },
}));

export default function TranslationInfoForm({ formData, setModalStatus, setSnackbarStatus, setMessage, successCallback }) {
    const { t } = useTranslation();


    
    const [openOrderNumberModal, setOrderNumberModalStatus] = useState(false);
    const [data, setData] = useState([]);

    const classes = useStyles();



    useEffect(() => {

    }, []);


    return (
        <>
            <TableFilterContainer>
            <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            value={formData.did}
                            label={"DID"}
                            name="DID"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            disabled={true}

                        />
                    </FormControl>
                </Grid>

                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            value={dayjs(formData.requested_at).format('DD/MM/YYYY HH:mm')}
                            label={"Requested At"}
                            name="name"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            disabled={true}

                        />
                    </FormControl>
                </Grid>

                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            value={dayjs(formData.scheduled_at).format('DD/MM/YYYY HH:mm')}
                            label={"Scheduled At"}
                            name="address"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            disabled={true}
                        />
                    </FormControl>
                </Grid>

                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            value={ formData.finalized_at ? dayjs(formData.finalized_at).format('DD/MM/YYYY HH:mm') : ''}
                            label={"Finalized At"}
                            name="address"
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            disabled={true}

                        />
                    </FormControl>
                </Grid>

                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            value={ "Status: " + getLabelByValue(TranslationStatus(), formData.change_status)}
                            margin="normal"
                            variant="outlined"
                            color="secondary"
                            disabled={true}

                        />
                    </FormControl>
                </Grid>

                <Grid item md={2} xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            value={formData.customer_notes}
                            margin="normal"
                            label={"Customer Notes"}
                            variant="outlined"
                            color="secondary"
                            disabled={true}
                        />
                    </FormControl>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <div>Current Route</div>
                            <FormControl fullWidth>
                                <TextField
                                    value={formData.fields_as_is.landline}
                                    label={"Landline"}
                                    name="priority"
                                    margin="normal"
                                    variant="outlined"
                                    color="secondary"
                                    disabled={true}

                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    value={formData.fields_as_is.mobile}
                                    label={"Mobile"}
                                    name="priority"
                                    margin="normal"
                                    variant="outlined"
                                    color="secondary"
                                    disabled={true}

                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    value={formData.fields_as_is.payphone}
                                    label={"Payphone"}
                                    name="weight"
                                    margin="normal"
                                    variant="outlined"
                                    color="secondary"
                                    disabled={true}

                                />
                            </FormControl>

                            <FormControl fullWidth>
                                <TextField
                                    value={formData.fields_as_is.route_type == 2 ? "Route Type: IP" : formData.fields_as_is.route_type == 3 ? "Route Type: PSTN" : ""}
                                    name="weight"
                                    margin="normal"
                                    variant="outlined"
                                    color="secondary"
                                    disabled={true}
                                />
                            </FormControl>

                            <FormControl fullWidth>
                                <TextField
                                    value={formData.fields_as_is.route_type == 2 ? "Trunk Name: " + formData.fields_as_is.trunk_name : "TDM Country: " + formData.fields_as_is.tdm_country_name}
                                    margin="normal"
                                    variant="outlined"
                                    color="secondary"
                                    disabled={true}
                                />
                            </FormControl>


                    </Grid>

                    <Grid item xs={6}>
                        <div>Requested Route</div>
                        <FormControl fullWidth>
                            <TextField
                                value={formData.fields_to_be.landline}
                                label={"Landline"}
                                name="priority"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                disabled={true}

                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField
                                value={formData.fields_to_be.mobile}
                                label={"Mobile"}
                                name="priority"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                disabled={true}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField
                                value={formData.fields_to_be.payphone}
                                label={"Payphone"}
                                name="weight"
                                margin="normal"
                                variant="outlined"
                                color="secondary"
                                disabled={true}

                            />
                        </FormControl>


                        <FormControl fullWidth>
                                <TextField
                                    value={formData.fields_to_be.route_type == 2 ? "Route Type: IP" : formData.fields_to_be.route_type == 3 ? "Route Type: PSTN" : ""}
                                    name="weight"
                                    margin="normal"
                                    variant="outlined"
                                    color="secondary"
                                    disabled={true}
                                />
                            </FormControl>

                            <FormControl fullWidth>
                                <TextField
                                    value={formData.fields_to_be.route_type == 2 ? "Trunk Name: " + formData.fields_to_be.trunk_name : "TDM Country: "+ formData.fields_to_be.tdm_country_name}
                                    margin="normal"
                                    variant="outlined"
                                    color="secondary"
                                    disabled={true}
                                />
                            </FormControl>

                    </Grid>

                </Grid>

            </TableFilterContainer>

            <br />
            <Grid sx={{ alignItems: "center" }} justifyContent="right" container spacing={0}>
                <SearchButtonContainer item >
                    <BaseButton
                        label={'Close'}
                        onClick={() => setModalStatus(false)} color={"--color-search"}
                    />
                </SearchButtonContainer>
            </Grid>
        </>
    );
}

